import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments';
import Utils from '@shared/helpers/utils';
import { Cliente } from '@shared/models/cliente.interface';
import { Documento } from '@shared/models/documento.interface';
import Fornecedor from '@shared/models/fornecedor.interface';
import PaginateResponse from '@shared/models/paginateResponse.interface';
import { Usuario } from '@shared/models/usuario.interface';
import { DocumentoService } from '@shared/services/documento.service';
import { FileService } from '@shared/services/file.service';
import { UserService } from '@shared/services/user.service';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { Subject, takeUntil } from 'rxjs';
import { CriarDocumentoComponent } from '../criar-documento/criar-documento.component';
import { EditarDocumentoModalComponent } from '../editar-documento-modal/editar-documento-modal.component';
import { ExibirDocumentoComponent } from '../exibir-documento/exibir-documento.component';


@Component({
  selector: 'app-listar-meus-documentos',
  templateUrl: './listar-meus-documentos.component.html',
  styleUrls: ['./listar-meus-documentos.component.scss']
})
export class ListarMeusDocumentosComponent implements OnInit {

  @Input('fornecedor') fornecedor: Fornecedor;
  cliente: Cliente = null;
  editDocumentoDialog: boolean = false;
  documentos: Documento[] = [];
  documento: Documento;
  selectedDocumentos: Documento[];
  loading: boolean = false;
  private destroyed$ = new Subject();
  totalRecords: number = 0;
  offset: number = 0;
  limit: number = 10;
  totalPages: number = 0;
  page: number = 1;
  pagingCounter: number = 1;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number;
  nextPage: number;
  somenteVisualizar: boolean = false;
  @ViewChild('dtDocumentos') dtDocumentos: Table;
  user: Usuario;
  environment = environment;
  statuses = Utils.getStatusToRequisicaoDocumento();
  contexto: string;
  mapaDeAcoes = {
    visualizar: ['backoffice', 'comprador', 'vendedor'],
    editar: ['backoffice', 'vendedor'],
    excluir: ['backoffice', 'vendedor'],
    criar: ['backoffice', 'vendedor'],
    renovar: ['vendedor','backoffice'],
  }
  deleteRef: ConfirmationService;

  constructor(
    private _documentoService: DocumentoService,
    private _confirmationService: ConfirmationService,
    private _userService: UserService,
    private _messageService: MessageService,
    private _dialogService: DialogService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _fileService: FileService,
  ) {

    this.contexto = Utils.getContextoDaRota(this._router)
    this._userService.getUserData().pipe(takeUntil(this.destroyed$)).subscribe((data: Usuario) => {
      this.user = data;
      this.cliente = this.user.clienteComprador
    })
  }

  ngOnDestroy(): void {
    this._confirmationService.close();
  }

  ngOnInit() {
    this.loadDocuments();
  }

  loadDocuments(event?: LazyLoadEvent) {
    try {
      this.loading = true;
      let opt = this.contexto;
      if(this.fornecedor) opt+="_fornecedor"
      switch (opt) {
        case 'comprador':
          this._documentoService.listarDocumentosDoCliente(this.cliente.id, event)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((res: PaginateResponse) => {
              this.documentos = res.docs;
              this.totalRecords = res.totalDocs;
              this.totalPages = res.totalPages;
              this.limit = res.limit;
              this.loading = false;
            }, (error) => {
              this.loading = false;
              console.error(error)
              this._messageService.add({ severity: 'error', summary: 'Houve um erro!', detail: error.error.message, sticky: true , key: "app" });
            }, () => {
              this.loading = false;
            });
          break;
        case 'comprador_fornecedor':
          this._documentoService.listarDocumentosEntreClienteEhFornecedor(this.cliente.id,this.fornecedor.id, event)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((res: PaginateResponse) => {
              this.documentos = res.docs;
              this.totalRecords = res.totalDocs;
              this.totalPages = res.totalPages;
              this.limit = res.limit;
              this.loading = false;
            }, (error) => {
              this.loading = false;
              console.error(error)
              this._messageService.add({ severity: 'error', summary: 'Houve um erro!', detail: error.error.message, sticky: true , key: "app" });
            }, () => {
              this.loading = false;
            });
          break;
        case 'vendedor':
          this._documentoService.listarDocumentosDoVendedor(this.user.id, event)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((res: PaginateResponse) => {
              this.documentos = res.docs;
              this.totalRecords = res.totalDocs;
              this.limit = res.limit;
              this.loading = false
            }, (error) => {
              this.loading = false;
              console.error(error)
              this._messageService.add({ severity: 'error', summary: 'Houve um erro!', detail: error.error.message , key: "app" });
            }, () => {
              this.loading = false;
            });
          break;
        case 'backoffice':
          // listarDocumentos
          this._documentoService.listarDocumentos(event)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((res: PaginateResponse) => {

              this.documentos = res.docs;
              this.totalRecords = res.totalDocs;
              this.limit = res.limit;
              this.loading = false
            }, (error) => {
              this.loading = false;
              console.error(error)
              this._messageService.add({ severity: 'error', summary: 'Houve um erro!', detail: error.error.message , key: "app" });
            }, () => {
              this.loading = false;
            });
          break;
        default:
          break;
      }
    } catch (error) {
      this.loading = false;
      this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `${error?.message}`, sticky: true, key: "app" })
    }
  }
  viewFile(documento) {
    const url = this._fileService.urlViewFile(documento.id || documento._id);
    window.open(url, '_blank');
  }
  downloadFile() {
    const url = this._fileService.urlDownloadFile(this.documento.file.id || this.documento.file._id);
    window.open(url, '_blank');
  }

  edit(documento: Documento) {
    if (!this.mapaDeAcoes.editar.includes(this.contexto) || documento.edicaoBloqueada == true) {
      this._messageService.add({ severity: 'warn', summary: 'Atençāo', detail: `Você nāo pode executar essa açāo!` , key: "app" })
      return
    };
    this.documento = null;
    this.documento = Utils.convertIds(documento);
    const ref = this._dialogService.open(EditarDocumentoModalComponent, {
      data: {
        documento: this.documento,
      },
      header: `Editar Documento: ${this.documento.nome} #${this.documento.id || documento._id}`,
      height: '80vh',
      width: '80%',
      maximizable: true,
    })
    ref.onClose.subscribe((documentos: any) => {
      // this.dtDocumentos.reset()
    });
  }
  show(documento: Documento) {
    if (!this.mapaDeAcoes.visualizar.includes(this.contexto)) {
      this._messageService.add({ severity: 'warn', summary: 'Atençāo', detail: `Você nāo pode executar essa açāo!` , key: "app" })
      return
    };
    this.documento = null;
    this.documento = Utils.convertIds(documento);
    const ref = this._dialogService.open(ExibirDocumentoComponent, {
      data: {
        documento: this.documento,
      },
      header: `Exibir Documento: ${this.documento.nome} #${this.documento.id || documento._id}`,
      height: '80vh',
      width: '80%',
      maximizable: true,
    })
    ref.onClose.subscribe((documentos: any) => {
      // this.dtDocumentos.reset()
    });
  }

  delete(documento: Documento) {
    try {
      if (!this.mapaDeAcoes.excluir.includes(this.contexto) || documento.edicaoBloqueada == true) {
        this._messageService.add({ severity: 'warn', summary: 'Atençāo', detail: `Você nāo pode executar essa açāo!` , key: "app" })
        return
      };
      const deleteRef = this._confirmationService.confirm({
        message: 'Você realmente deseja deletar o documento ' + documento.nome + '?',
        header: 'Confirmar',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this._documentoService.deleteOne(documento.id || documento._id).subscribe(
            (res: any) => {

              this.documentos = this.documentos.filter(val => val._id !== (documento.id || documento._id));
              this.documento = {};
              this._messageService.add({ severity: 'success', summary: 'Ótimo', detail: 'documento deletado' , key: "app" });
            },
            (error: any) => {
              console.error(error)
              this._messageService.add({ severity: 'error', summary: 'Houve um erro!', detail: error.error.message, sticky: true , key: "app" });
            }
          )
        },
        reject: () => {
          deleteRef.close()
        }
      });
    } catch (error) {
      this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `${error?.message}`, sticky: true, key: "app" })
    }
  }

  criarNovo() {
    if (!this.mapaDeAcoes.criar.includes(this.contexto)) {
      this._messageService.add({ severity: 'warn', summary: 'Atençāo', detail: `Você nāo pode executar essa açāo!` , key: "app" })
      return
    };
    const ref = this._dialogService.open(CriarDocumentoComponent, {
      header: "Criar Documento",
      height: "80vh",
      width: "80%",
      maximizable: true,
    })
    ref.onClose.subscribe((documentos: any) => {
      // this.dtDocumentos.reset()
    });
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.documentos.length; i++) {
      if (this.documentos[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }
  clear(table: Table, storageKey: string) {
    Utils.clearTableFilters(table, storageKey)
  }
  messageShow() {
    this._messageService.add({ severity: 'success', summary: 'Ótimo', detail: 'Teste de Messagem', sticky: true , key: "app" });
  }
  renovar(documento: Documento) {
      this._router.navigate([`${environment.routes.vendedor.documentos.renovar}`,`${documento.id || documento._id}`]);
  }
}
