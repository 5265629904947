import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { FornecedorResolver } from '@shared/resolvers/fornecedor.resolver';
import { CriarDocumentoComponent } from './criar-documento/criar-documento.component';
import { GerenciarDocumentoFornecedorComponent } from './gerenciar-documento-fornecedor/gerenciar-documento-fornecedor.component';
import { ExibirDocumentoComponent } from './exibir-documento/exibir-documento.component';
import { EditarDocumentoModalComponent } from './editar-documento-modal/editar-documento-modal.component';
import { DocumentoResolver } from '@shared/resolvers/documento.resolver';
import { RenovarDocumentoComponent } from './renovar-documento/renovar-documento.component';
import { RequisicaoByDocumentoResolver } from '@shared/resolvers/requisicao-by-documento.resolver';

export const routes: Route[] = [
  {
    path: 'cadastrar',
    component: CriarDocumentoComponent,
  },
  {
    path: 'listar',
    component: GerenciarDocumentoFornecedorComponent,
  },
  {
    path: 'editar/:id',
    component: EditarDocumentoModalComponent,
    resolve: {
      documento: DocumentoResolver
    }
  },
  {
    path: 'exibir/:id',
    component: ExibirDocumentoComponent,
    resolve: {
      documento: DocumentoResolver
    }
  },
  {
    path: 'renovar/:id',
    component: RenovarDocumentoComponent,
    resolve: {
      documento: DocumentoResolver,
      requisicaoDocumento: RequisicaoByDocumentoResolver,
    }
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentoRoutingModule { }
