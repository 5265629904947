import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxViacepModule } from '@brunoc/ngx-viacep';
import { MaterialModule } from '@shared/material/material.module';
import { SharedModule } from '@shared/shared.module';
import { TreoCardModule } from '@treo/components/card';
import { TreoMessageModule } from '@treo/components/message';
import { NgxMaskModule } from 'ngx-mask';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { StepsModule } from 'primeng/steps';
import { AutocompleteVincularVendedorFornecedorComponent } from './autocomplete-vincular-vendedor-fornecedor/autocomplete-vincular-vendedor-fornecedor.component';
import { MessageInputRequiredComponent } from './message-input-required/message-input-required.component';
import { ModeloDocumentoAlertModule } from './modelo-documento-alert/modelo-documento-alert.module';
import { ValidarCnpjComponent } from './validar-cnpj/validar-cnpj.component';
import { FormulariosModule } from './formularios/formularios.module';


@NgModule({
  declarations: [
    ValidarCnpjComponent,
    MessageInputRequiredComponent,
    AutocompleteVincularVendedorFornecedorComponent,


  ],
  exports: [
    ValidarCnpjComponent,
    MessageInputRequiredComponent,
    AutocompleteVincularVendedorFornecedorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MaterialModule,
    TreoMessageModule,
    MaterialModule,
    TreoMessageModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    TreoCardModule,
    StepsModule,
    MatStepperModule,
    NgxMaskModule.forRoot(),
    ButtonModule,
    NgxViacepModule,
    AutoCompleteModule,
    MultiSelectModule,
    InputMaskModule,
    DropdownModule,
    InputTextModule,
    InputSwitchModule,
    ModeloDocumentoAlertModule,
    // DocumentoModule,
  ]
})
export class ComponentesModule { }
