<div class="content-layout" [ngClass]="'fullwidth-standard-content-scroll'">
  <!-- Header -->
  <div class="header">
      <div class="left">
          <h1>Dashboard</h1>
      </div>
      <div class="right">
          <!-- Action buttons -->

          <div class="flex items-center w-100 gt-xs:items-baseline justify-end">
          <span class="p-float-label">
          <p-dropdown
                [options]="user.fornecedores"
                [(ngModel)]="empresaSelected"
                [showClear]="true"
                [placeholder]="'Selecione a empresa'"
                [filter]="true"
                [filterBy]="'nome,rawCNPJ'"
                [optionLabel]="'nome'"
                [appendTo]="'body'"
                name="cnpj"
                (onChange)="newSelection($event)"
                >
                <ng-template pTemplate="selectedItem">
                  <div class="empresa-item empresa-item-value" *ngIf="empresaSelected"> 
                      <div>{{empresaSelected.nome | truncate: 32 }}</div>
                  </div>
              </ng-template>
              <ng-template let-empresa pTemplate="item">
                  <div class="empresa-item">
                      <div>{{empresa.nome}} - {{ empresa.cnpj }}</div>
                  </div>
              </ng-template>
            </p-dropdown> 
            <label for="cnpj">Selecione a Empresa</label> 
            </span>
          </div>
      </div>
  </div>
  <!-- Main -->
  <div class="main">
    <!-- <div class="flex flex-auto mb-3" *ngIf="lastPostUnread">
      <a
        class="relative flex flex-col hidden flex-auto p-3 bg-card border-green rounded border-l-4 shadow-md overflow-hidden"
        [href]="'/vendedor/help-center/posts/'+lastPostUnread.slug">
        <div class="flex items-center">
          <div class="flex flex-row">
            <div class="font-bold text-md text-secondary normal-case tracking-wider">{{lastPostUnread.title}}
              <strong>Saiba mais...</strong></div>
          </div>
        </div>
      </a>
      </div> -->
    <div class="flex flex-auto mb-3 " *ngIf="isLoading" >
      <treo-message 
              
              class="w-full"
              [type]="'info'"
              [appearance]="'border'">
          <span treoMessageTitle>Atençāo</span>
          Estamos buscando os dados atualizados, aguarde...
      </treo-message>
    </div>

    <div class="graficos" *ngIf="user?.cnpj?.length > 0">
      <div class="grid grid-cols-3 md:grid-2 sm:grid-cols-1 xs:grid-cols-1 gap-4 ">
        <div class="mt-2 pb-2 pr-2 bg-card shadow-xl rounded overflow-hidden text-center">
          <div *ngIf="isLoading">
            <mat-progress-bar mode="query" style="width: 100em!important; height: 3px !important"></mat-progress-bar>
          </div>
          <div class="mt-4"><span class="font-semibold">Total de Itens Vendidos</span></div>
          <!-- <div *ngIf="ftimes != user.clientes.length"><mat-progress-bar mode="query" style="height: 1px !important"></mat-progress-bar></div> -->
          <apx-chart [series]="chartOptionsItensVendidos.series" [chart]="chartOptionsItensVendidos.chart"
            [dataLabels]="chartOptionsItensVendidos.dataLabels" [plotOptions]="chartOptionsItensVendidos.plotOptions"
            [yaxis]="chartOptionsItensVendidos.yaxis" [xaxis]="chartOptionsItensVendidos.xaxis"
            [stroke]="chartOptionsItensVendidos.stroke" [fill]="chartOptionsItensVendidos.fill"
            [colors]="chartOptionsItensVendidos.colors"></apx-chart>
        </div>
        <div id="chart_itens_abertos" class="mt-2 pb-2 pr-2 bg-card shadow-xl rounded overflow-hidden text-center">
          <div *ngIf="isLoading">
            <mat-progress-bar mode="query" style="width: 100em!important; height: 3px !important"></mat-progress-bar>
          </div>
          <div class="mt-4"><span class="font-semibold mt-4">Total de Cotaçōes em Aberto</span></div>
          <apx-chart [series]="chartOptionsItensAbertos.series" [chart]="chartOptionsItensAbertos.chart"
            [dataLabels]="chartOptionsItensAbertos.dataLabels" [plotOptions]="chartOptionsItensAbertos.plotOptions"
            [yaxis]="chartOptionsItensAbertos.yaxis" [xaxis]="chartOptionsItensAbertos.xaxis"
            [stroke]="chartOptionsItensAbertos.stroke" [colors]="chartOptionsItensAbertos.colors"
            [fill]="chartOptionsItensAbertos.fill"></apx-chart>
        </div>
        <div id="chart_itens_fechados" class="mt-2 pb-2 pr-2 bg-card shadow-xl rounded overflow-hidden text-center">
          <div *ngIf="isLoading">
            <mat-progress-bar mode="query" style="width: 100em!important; height: 3px !important"></mat-progress-bar>
          </div>
          <div class="mt-4"><span class="font-semibold mt-4">Total de Cotaçōes Fechadas</span></div>

          <apx-chart [series]="chartOptionsItensFechados.series" [chart]="chartOptionsItensFechados.chart"
            [dataLabels]="chartOptionsItensFechados.dataLabels" [plotOptions]="chartOptionsItensFechados.plotOptions"
            [yaxis]="chartOptionsItensFechados.yaxis" [xaxis]="chartOptionsItensFechados.xaxis"
            [stroke]="chartOptionsItensFechados.stroke" [colors]="chartOptionsItensFechados.colors"
            [fill]="chartOptionsItensFechados.fill"></apx-chart>
        </div>
      </div>
    </div>
  </div>

</div>