<div [ngClass]="inRoute ? 'content-layout fullwidth-standard-content-scroll' : ''">
  <!-- Header -->
  <div class="header" *ngIf="inRoute">
    <div class="left">
      <h1>{{ 'Renovaçāo do Documento: ' + documento.nome }}</h1>
      <h6>{{ '#' + documento.id }}</h6>
    </div>
    <div class="right">
      <!-- Action buttons -->
    </div>
  </div>

  <div class="max-w">
    <!-- Exibição do Alerta -->
    <ng-template #alertaAcimaTemplate let-modelo>
      <modelo-documento-mensagem-alert 
        [modelosDocumentos]="[modelo]"
        [mensagemPosicao]="'acima'">
      </modelo-documento-mensagem-alert>
    </ng-template>
    <div *ngIf="modelosDocumento?.length">
      <ng-container [ngTemplateOutlet]="alertaAcimaTemplate"
                  [ngTemplateOutletContext]="{ $implicit: modelosDocumento[0] }">
      </ng-container>
    </div>
    <form [formGroup]="form" autocomplete="disabled" *ngIf="form"
      class="flex flex-col p-8 pb-0 bg-card shadow-xl rounded overflow-hidden">
      <div class="flex flex-col gap-4">
        <div
          class="treo-mat-dense w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4">
          <div class="treo-mat-dense flex-auto">
            <span class="label font-semibold">Fornecedor</span>
            <input matInput class="mat-input-element" [ngModel]="documento?.fornecedor?.nome" readonly
              [ngModelOptions]="{standalone: true}" readonly />
          </div>
          <div class="treo-mat-dense flex-auto">
            <span class="label font-semibold">Tipo do Documento</span>
            <input matInput class="mat-input-element" [ngModel]="documento?.tipo?.nome" readonly
              [ngModelOptions]="{standalone: true}" readonly />
          </div>
        </div>
        <div
          class="treo-mat-dense w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4">
          <mat-form-field class="w-full">
            <mat-label>Nome do Documento</mat-label>
            <input matInput formControlName="nome" required>
            <mat-error *ngIf="form && form.get('nome')?.hasError('required')">
              O campo Nome é obrigatório.
            </mat-error>
            <mat-hint 
            *ngIf="modelosDocumento?.length && modelosDocumento[0]?.nome" 
            class="cursor-pointer text-primary-800 hover:text-primary-600" 
            (click)="alterarNomePorModelo()">
            Você pode colocar o nome igual ao do modelo indicado pelo cliente, clicando aqui.
          </mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Número</mat-label>
            <input matInput formControlName="numero" required>
            <mat-error *ngIf="form && form.get('numero')?.hasError('required')">O campo Número é
              obrigatório.</mat-error>
          </mat-form-field>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4">
          <div class="field">
            <label>Data de Emissão</label>
            <p-calendar 
                formControlName="dataEmissao"
                [showIcon]="true"
                [locale]="ptBR"
                dateFormat="dd/mm/yy"
                [maxDate]="hoje"
                [readonlyInput]="true"
                [style]="{'width':'100%'}"
                placeholder="Selecione a data"
                appendTo="body"
                [showTime]="false"
                [showButtonBar]="true"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [yearRange]="'1900:2050'"
                class="w-full">
            </p-calendar>
            <small class="p-error block" *ngIf="form.get('dataEmissao')?.hasError('required')">
              O campo Data de Emissão é obrigatório.
            </small>
          </div>
        
          <div class="field">
            <label>Data de Validade</label>
            <p-calendar 
              formControlName="dataValidade"
              [showIcon]="true"
              dateFormat="dd/mm/yy"
              [minDate]="hoje"
              [locale]="ptBR"
              [maxDate]="dataValidadeMaxima"
              [readonlyInput]="true"
              [style]="{'width':'100%'}"
              placeholder="Selecione a data"
              appendTo="body"
              [showTime]="false"
              [showButtonBar]="true"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [yearRange]="'1900:2050'"
              class="w-full">
          </p-calendar>
            <small class="p-error block" *ngIf="form.get('dataValidade')?.hasError('required')">
              O campo Data de Validade é obrigatório.
            </small>
            <small class="block text-primary-800 hover:text-primary-600" 
              *ngIf="modelosDocumento?.length && modelosDocumento[0]?.validadeDocumentoQuantidade && modelosDocumento[0]?.validadeDocumentoPeriodo">
              O modelo do cliente define validade máxima de {{ modelosDocumento[0].validadeDocumentoQuantidade }} 
              {{ modelosDocumento[0].validadeDocumentoPeriodo === 'dia' ? 'dias' : 
                 modelosDocumento[0].validadeDocumentoPeriodo === 'semana' ? 'semanas' : 
                 modelosDocumento[0].validadeDocumentoPeriodo === 'mes' ? 'meses' : 'anos' }}.
            </small>
          </div>
        </div>
        <div class="field col-12">
          <label class="block mb-2" for="observacao">Observação</label>
          <textarea 
            class="w-full border-2 rounded" 
            inputId="observacao" 
            rows="2" 
            cols="30" 
            pInputTextarea 
            maxlength="300"
            formControlName="observacao" 
            [ngClass]="{'ng-invalid ng-dirty': form.get('observacao')?.invalid && form.get('observacao')?.touched}"
            placeholder="Digite uma observação">
          </textarea>
          <small 
            class="p-error" 
            *ngIf="form.get('observacao')?.invalid && form.get('observacao')?.touched">
            O campo Observação é obrigatório.
          </small>
        </div>

        <div class="flex flex-col gap-0">
          <mat-form-field appearance="fill" class="treo-mat-dense flex-auto">
            <mat-label>Novo Arquivo</mat-label>
            <ngx-mat-file-input #file placeholder="Envie o arquivo"
              [accept]="'.doc, .docx, .xls, .xlsm, .pdf, .png, .jpg, .jpeg'" formControlName="file"
              (change)="handleFileChange($event)"></ngx-mat-file-input>
            <button mat-icon-button matSuffix *ngIf="file.value" (click)="file.clear()">
              <mat-icon>clear</mat-icon>
            </button>
            <mat-icon matSuffix>folder</mat-icon>
            <mat-error *ngIf="form && form?.get('file')?.hasError('required')">O arquivo é obrigatório.</mat-error>
          </mat-form-field>
          <small id="file-help" class="block">Formatos aceitos: .doc, .docx, .xls, .xlsm, .pdf, .png, .jpg,
            .jpeg</small>
        </div>

          <!-- Template Alerta Abaixo -->
          <ng-template #alertaAbaixoTemplate let-modelo>
            <modelo-documento-mensagem-alert 
              [modelosDocumentos]="[modelo]"
              [mensagemPosicao]="'abaixo'">
            </modelo-documento-mensagem-alert>
          </ng-template>

          <!-- Exibição do Alerta Abaixo -->
          <div *ngIf="modelosDocumento?.length">
            <ng-container [ngTemplateOutlet]="alertaAbaixoTemplate"
                        [ngTemplateOutletContext]="{ $implicit: modelosDocumento[0] }">
            </ng-container>
          </div>
        <div class="treo-mat-dense flex-auto">
          <label>Arquivo Antigo a ser Substituído</label>
          <treo-message class="w-full mt-1" [type]="'basic'" [showIcon]="true"> <mat-icon class="mr-2" treoMessageIcon
              [svgIcon]="'insert_drive_file'"></mat-icon>
            <div class="flex items-center justify-between">
              <div class="flex-1">{{ getFileName(documento.file) }}</div>
              <div class="flex">
                <button mat-icon-button class="mx-2" (click)="downloadFile()"> <mat-icon>download</mat-icon> </button>
                <button mat-icon-button class="mx-2" (click)="viewFile()"> <mat-icon>open_in_new</mat-icon> </button>
              </div>
            </div>
          </treo-message>
        </div>

        <div class="treo-mat-dense flex-auto mb-5">
          <div class="flex justify-start items-center">
            <!-- <label>Visualizar Arquivo Antigo a ser Substituído</label> -->
            <button pButton 
                    pRipple 
                    type="button" 
                    [label]="mostrarArquivo ? 'Ocultar Arquivo' : 'Mostrar Arquivo'"
                    [icon]="mostrarArquivo ? 'pi pi-eye-slash' : 'pi pi-eye'"
                    class="p-button-text p-button-outlined p-button-rounded "
                    (click)="mostrarArquivo = !mostrarArquivo"
                    [pTooltip]="mostrarArquivo ? 'Ocultar Arquivo' : 'Mostrar Arquivo'">
            </button>
          </div>
        
          <div class="mt-1" [@fadeInOut] *ngIf="mostrarArquivo">
            <ng-container *ngIf="mimeType && mimeType.startsWith('image/')">
              <p-image [src]="urlFile" 
                       alt="Image" 
                       width="250" 
                       [preview]="true"
                       (onImageError)="erroImage($event)">
              </p-image>
            </ng-container>
        
            <ng-container *ngIf="mimeType && mimeType === 'application/pdf'">
              <ngx-extended-pdf-viewer 
                [src]="urlFile" 
                [height]="'600px'" 
                [width]="'100%'" 
                [useBrowserLocale]="true"
                [showToolbar]="false" 
                [originalSize]="false" 
                [minZoomLevel]="0.5" 
                [maxZoomLevel]="3"
                [zoom]="'page-width'">
              </ngx-extended-pdf-viewer>
            </ng-container>
        
            <ng-container *ngIf="mimeType && !mimeType.startsWith('image/') && mimeType !== 'application/pdf'">
              <div class="flex flex-col items-center justify-center space-y-2">
                <p>O Documento com extensāo "<strong>{{ (mimeType.split("/")[1]).split(".")[1] }}</strong>" não pode ser
                  visualizado aqui, será necessário realizar o download do arquivo.</p>
                <button pButton 
                        pRipple 
                        type="button" 
                        class="p-button-raised p-button-secondary"
                        (click)="downloadFile()">
                  Fazer o Download do Arquivo
                </button>
              </div>
            </ng-container>
        
            <ng-container *ngIf="documento == null">
              <h6 class="text-center">Documento indisponível para visualização.</h6>
            </ng-container>
          </div>
        </div>
      </div>
      <div
        class="flex items-center justify-end border-t -mx-8 mt-5 px-8 py-5 light:bg-cool-gray-50 dark:bg-cool-gray-700">
        <treo-message [dismissed]="!message.show" [appearance]="'border'" [showIcon]="'true'" [type]="message.type">
          {{message.content}}
        </treo-message>
        <button pButton pRipple label="Cancelar" *ngIf="inRoute" icon="pi pi-times" class="p-button-text"
          (click)="cancelar()"></button>
        <button mat-raised-button (click)="upload()" [class.spinner]="loading" [disabled]="loading" color="primary"
          class="px-6 ml-3">
          Enviar
        </button>
      </div>
    </form>
  </div>
</div>