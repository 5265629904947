import { Location } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Documento } from '@shared/models/documento.interface';
import Fornecedor from '@shared/models/fornecedor.interface';
import ModeloDocumento from '@shared/models/modeloDocumento.interface';
import { TreoMessage } from '@shared/models/treoMessage.interface';
import { Usuario } from '@shared/models/usuario.interface';
import { FileSizePipe } from '@shared/pipes/fileSize.pipe';
import { DocumentoService } from '@shared/services/documento.service';
import { DocumentoTiposService } from '@shared/services/documentoTipos.service';
import { FileService } from '@shared/services/file.service';
import { UserService } from '@shared/services/user.service';
import { addDays, addMonths, addWeeks, addYears, format } from 'date-fns';
import { FileInputComponent } from 'ngx-material-file-input';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'criar-documento',
  templateUrl: './criar-documento.component.html',
  styleUrls: ['./criar-documento.component.scss']
})
export class CriarDocumentoComponent implements OnInit,OnDestroy,AfterViewInit,OnChanges {
  private destroyed$ = new Subject();
  form: FormGroup;
  documentoTipos: any[] = [];
  fileName: string;
  user: Usuario;
  selectedFile;
  uploadedFiles = [];
  loading: boolean = false;
  fornecedores:Fornecedor[];
  documento: Documento
  inRoute: boolean = false;
  message: TreoMessage  = {
    show: false,
    appearance: 'outline',
    showIcon: true
  };
  submitted = false;
  @ViewChild("file") file: FileInputComponent;
  @Output('documento') documentoEmitter = new EventEmitter<Documento>();
  @Output('form') formChange = new EventEmitter<FormGroup>();
  @Input('idTipoDeDocumentoPrefixado') idTipoDeDocumentoPrefixado: string = null;
  @Input('idFornecedorPrefixado') idFornecedorPrefixado: string = null;
  @Input('idClientePrefixado') idClientePrefixado: string = null;
  @Input('autoCloseOnFinish') autoCloseOnFinish: boolean = true;
  @Input('modeloDocumento') modeloDocumento: ModeloDocumento = null;
  @Input('acimaModeloDocumentoMensagemAlert') acimaModeloDocumentoMensagemAlert: TemplateRef<any>;
  @Input('abaixoModeloDocumentoMensagemAlert') abaixoModeloDocumentoMensagemAlert: TemplateRef<any>;

  constructor(
    private _formBuilder: FormBuilder,
    private _documentoService: DocumentoService,
    private _documentoTipoService: DocumentoTiposService,
    private _userService: UserService,
    private sanitizer: DomSanitizer,
    private _fileSizePipe: FileSizePipe,
    private _messageService: MessageService,
    private _location: Location,
    private _activatedRoute: ActivatedRoute,
    private _dinamicDialogRef: DynamicDialogRef,
    private _fileService: FileService,
    ) {

      this.inRoute = this._activatedRoute.snapshot.queryParams.create;
      this._userService.getUserData().pipe(takeUntil(this.destroyed$)).subscribe((data: Usuario) => {
        this.user = data;
        this.fornecedores = this.user.fornecedores;
      })

  }
  get formControl() {
    return this.form.controls;
  }
  ngOnDestroy(): void {
  }
  ngOnInit(): void {
    this._documentoTipoService.getSomenteAtivosProspect(this.idClientePrefixado).pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.documentoTipos = data;
    })
    this.form = this._formBuilder.group({
      numero: [''],
      dataEmissao: ['', Validators.required],
      dataValidade: [null],
      nome: ['', Validators.required],
      tipo: [(this.idTipoDeDocumentoPrefixado || null), Validators.required],
      file: [null, Validators.required],
      fornecedor: [(this.idFornecedorPrefixado || null), Validators.required],
      // codigoDeVerificacao: [null],
      // inscricaoMunicipal: [null],
      observacao: [''],
      enviadoPor: [(this.user?.id)],
    });
    this.formChange.emit(this.form);
    if (this.documento && this.documento.file) {
      this.form.controls.file.patchValue((this.documento.file.id), { disabled: true, readonly: true })
      this.file.disabled = true;
    }
    if (this.documento && this.documento.file) this.uploadedFiles = [this.documento.file];
  }
  viewFile() {
    const url = this._fileService.urlViewFile(this.documento.file.id || this.documento.file._id);
    window.open(url, '_blank');
  }
  ngAfterViewInit() {
  }
  salvar() {
    this.form.markAllAsTouched();
    this.submitted = true;
    if (!this.form.valid) {
      this.message.show = true,
      this.message.content = "Existe campos inválidos no formulário.";
      return;
    }
    this.loading = true;
    const formValues = this.form.getRawValue();
    this.message.show = false,
    this.message.content = null;
    const formData = new FormData();
    formData.append('numero', formValues.numero);
    formData.append('dataEmissao', formValues.dataEmissao);
    formData.append('dataValidade', formValues.dataValidade || null);
    // formData.append('codigoDeVerificacao', formValues.codigoDeVerificacao || null);
    // formData.append('inscricaoMunicipal', formValues.inscricaoMunicipal || null );
    formData.append('nome', formValues.nome);
    formData.append('fornecedor', (this.idFornecedorPrefixado ? this.idFornecedorPrefixado : formValues.fornecedor));
    formData.append('tipo', (this.idTipoDeDocumentoPrefixado ? this.idTipoDeDocumentoPrefixado : formValues.tipo));
    formData.append('file', formValues.file.files[0]);
    formData.append('enviadoPor', formValues.enviadoPor);
    formData.append('observacao', formValues.observacao);
    if(this.modeloDocumento) formData.append('modeloDocumento', this.modeloDocumento.id); 

    this._documentoService.uploadNew(formValues.fornecedor,formData).pipe(takeUntil(this.destroyed$)).subscribe(
      (response: any) => {
        
        const documento = response.data;
        this.message.content = `Documento criado com sucesso`;
        this.message.type = "success";
        this.message.show = true;
        this._messageService.add({ severity: 'success', summary: 'Ótimo', detail: `Documento criado com sucesso` , key: "app" })
        
        this.loading = false;
        this.documentoEmitter.emit(documento);
        this.form.reset();
        if(this.autoCloseOnFinish) this._dinamicDialogRef.close();
      },
      (error: any) => {
        this.message.content = `Houve um erro: ${error?.message}`
        this.message.type = "error";
        this.message.show = true;
        this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `${error?.message}`, sticky: true, key: "app" })
        console.error(error);
        this.loading = false;
      }
    );
  }
  showImage(image) {
    return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + image)
  }
  getSizeFormated(size: number) {
    return this._fileSizePipe.transform(size)
  }
  handleFileChange(event) {
    
  }
  cancelar(){
    this._location.back()
  } 
  ngOnChanges(changes: SimpleChanges) {
    if (changes.modeloDocumento) {
      const newValue = changes.modeloDocumento.currentValue;
      const oldValue = changes.modeloDocumento.previousValue;
      if (newValue) {
        this.form.removeControl("numero")
        this.form.controls.dataValidade.clearValidators();
        this.form.controls.nome.setValue(this.modeloDocumento.nome)
        this.form.controls.dataEmissao.setValue(format(new Date(),'yyyy-MM-dd'))
        if(this.modeloDocumento.validadeDocumentoQuantidade && this.modeloDocumento.validadeDocumentoPeriodo){
          switch (this.modeloDocumento.validadeDocumentoPeriodo) {
            case 'dia':
              this.form.get('dataValidade').setValue(format(addDays(new Date(), this.modeloDocumento.validadeDocumentoQuantidade),'yyyy-MM-dd'),{ readonly: true });
              break;
            case 'semana':
              this.form.get('dataValidade').setValue(format(addWeeks(new Date(), this.modeloDocumento.validadeDocumentoQuantidade),'yyyy-MM-dd'),{ readonly: true });
              break;
              break;
            case 'mes':
              this.form.get('dataValidade').setValue(format(addMonths(new Date(), this.modeloDocumento.validadeDocumentoQuantidade),'yyyy-MM-dd'),{ readonly: true });
              break;
              break;
            case 'ano':
              this.form.get('dataValidade').setValue(format(addYears(new Date(),this. modeloDocumento.validadeDocumentoQuantidade),'yyyy-MM-dd'),{ readonly: true });
              break;
              break;
            default:
              break;
          }
        }else {
          this.form.removeControl("dataValidade")
        }
      } 
    }
  }
}
