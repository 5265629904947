import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MaterialModule } from '../../../shared/material/material.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'app/shared/shared.module';
import { TreoCardModule } from '@treo/components/card';
import { DashboardComponent } from './dashboard.component';
import {DropdownModule} from 'primeng/dropdown';
import { TreoMessageModule } from '@treo/components/message';


@NgModule({
  declarations: [
    DashboardComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgApexchartsModule,
    MaterialModule,
    MatProgressBarModule,
    NgxMaskModule.forRoot(),
    TreoCardModule,
    DropdownModule,
    TreoMessageModule,
  ],
  exports: [
    DashboardComponent,
  ],
})
export class DashboardModule { }
