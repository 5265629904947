import { trigger, transition, style, animate } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments';
import { Documento } from '@shared/models/documento.interface';
import ModeloDocumento from '@shared/models/modeloDocumento.interface';
import { RequisicaoDocumento } from '@shared/models/requisicaoDocumento';
import { TreoMessage } from '@shared/models/treoMessage.interface';
import { Usuario } from '@shared/models/usuario.interface';
import { FileSizePipe } from '@shared/pipes/fileSize.pipe';
import { DocumentoService } from '@shared/services/documento.service';
import { DocumentoTiposService } from '@shared/services/documentoTipos.service';
import { FileService } from '@shared/services/file.service';
import { UserService } from '@shared/services/user.service';
import { addDays, addMonths, addWeeks, addYears, format, parse, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { FileInputComponent } from 'ngx-material-file-input';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-renovar-documento',
  templateUrl: './renovar-documento.component.html',
  styleUrls: ['./renovar-documento.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('200ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class RenovarDocumentoComponent implements OnInit, OnDestroy {
  form: FormGroup;
  documentoTipos: any[] = [];
  fileName: string;
  usuario: Usuario;
  contexto: string;
  selectedFile;
  uploadedFiles = [];
  loading: boolean = false;
  inRoute: boolean = false;
  role: string;
  message: TreoMessage = { show: false };
  mimeType;
  urlFile;
  hoje: Date = new Date();
  dataValidadeMaxima: Date;
  mostrarArquivo: boolean = false;

  @Input() documento: Documento;
  @Input() requisicaoDocumento: RequisicaoDocumento;
  @Input() openDialog: boolean;
  @Input('clienteId') clienteId?: string = null;
  @Input('modeloDocumento') modelosDocumento: ModeloDocumento[] = null;
  @ViewChild('alertaAcimaTemplate') alertaAcimaTemplate!: TemplateRef<any>;
  @ViewChild('alertaAbaixoTemplate') alertaAbaixoTemplate!: TemplateRef<any>;
  @ViewChild("file") file: FileInputComponent;
  private destroyed$ = new Subject();
  mensagemAcima: boolean = false;
  mensagemAbaixo: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _documentoService: DocumentoService,
    private _documentoTipoService: DocumentoTiposService,
    private _userService: UserService,
    private _fileSizePipe: FileSizePipe,
    private _messageService: MessageService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _config: DynamicDialogConfig,
    private _dinamicDialogRef: DynamicDialogRef,
    private _fileService: FileService,
    private _cdr: ChangeDetectorRef,
  ) {
    this._userService.getUserData().pipe(takeUntil(this.destroyed$)).subscribe((data: Usuario) => {
      this.usuario = data;
      this.contexto = this.usuario.roleSelected;
    });

    // Verifica se é dialog ou rota
    if (this._config.data && (this._config.data.create == true || this._config.data.documento)) {
      this.documento = this._config.data.documento;
      this.requisicaoDocumento = this._config.data.requisicaoDocumento;
    } else {
      this.inRoute = true;
      // Carrega dados do resolver                                              
      this.documento = this._activatedRoute.snapshot.data.documento;
      this.requisicaoDocumento = this._activatedRoute.snapshot.data.requisicaoDocumento;
    }
    this.verificarDocumento();
  }
  verificarDocumento(){
    if (this.documento) {
      this.mimeType = this.documento.file.mimetype;
      this.urlFile = this.getUrlFile(this.documento.file.id);
      this.modelosDocumento = [this.documento.modeloDocumento];
      this.clienteId = (this.documento?.cliente?.id || this.documento?.cliente?._id || null)
      this.configurarMensagens();
      this.buidForm();
    }else{
      this.cancelar()
    }

  }
  ngOnDestroy(): void {
    this.destroyed$.unsubscribe();
  }
  ngOnInit(): void {
    this.loadDocumentoTipos();
    if (this.documento?.modeloDocumento) {
      this.modelosDocumento = [].concat(this.documento.modeloDocumento);
      this._cdr.detectChanges();
    }
    this.configurarModeloDocumento();
  }
  private configurarMensagens(): void {
    if (!this.modelosDocumento || !Array.isArray(this.modelosDocumento)) {
      return;
    }

    this.modelosDocumento.forEach(modelo => {
      if (modelo.mensagemPosicao === 'acima') {
        this.mensagemAcima = true;
        this.alertaAcimaTemplate = modelo.instrucao;
      } else if (modelo.mensagemPosicao === 'abaixo') {
        this.mensagemAbaixo = true;
        this.alertaAbaixoTemplate = modelo.instrucao;
      }
    });
  }
  onShow() {
    this.buidForm()
  }
  getUrlFile(url) {
    return this._fileService.urlViewFile(url);
  }
  
buidForm() {
  const formatarData = (dataIso: string | null): Date | null => {
    if (!dataIso) return null;
    const data = parseISO(dataIso);
    // Ajusta timezone para local
    return new Date(
      data.getTime() + data.getTimezoneOffset() * 60000
    );
  };

  const dataEmissao = formatarData(this.documento.dataEmissao);
  const dataValidade = formatarData(this.documento.dataValidade);

  this.form = this._formBuilder.group({
    numero: [this.documento?.numero || null, Validators.required],
    dataEmissao: [dataEmissao, Validators.required],
    dataValidade: [dataValidade, Validators.required],
    nome: [this.documento?.nome || null, Validators.required],
    tipo: [(this.documento.tipo?.id || this.documento.tipo?._id) || null, Validators.required],
    atualizadoPor: [(this.usuario?.id || this.usuario?._id) || null],
    observacao: [this.documento?.observacao || null],
    cliente: [this?.clienteId || this.requisicaoDocumento?.cliente?.id],
    file: [null, Validators.required],
  });
}

// Método auxiliar para formatar data para exibição
formatarDataExibicao(data: Date | null): string {
  if (!data) return '';
  return format(data, 'dd/MM/yyyy', { locale: ptBR });
}
  viewFile() {
    const url = this._fileService.urlViewFile(this.documento.file.id || this.documento.file._id);
    window.open(url, '_blank');
  }
  downloadFile() {
    const url = this._fileService.urlDownloadFile(this.documento.file.id || this.documento.file._id);
    window.open(url, '_blank');
  }
    upload() {
    try {
      this._messageService.clear();
      this.form.markAllAsTouched();
      this.form.disable();
      
      if (this.form.invalid) {
        const invalidFields = Object.keys(this.form.controls)
          .filter(key => this.form.get(key)?.errors)
          .map(key => {
            const control = this.form.get(key);
            let errorMessage = '';
            
            if (control?.errors?.['required']) {
              errorMessage = `O campo ${key} é obrigatório`;
            } else if (control?.errors?.['min']) {
              errorMessage = `O campo ${key} deve ser maior que ${control.errors['min'].min}`;
            } else if (control?.errors?.['max']) {
              errorMessage = `O campo ${key} deve ser menor que ${control.errors['max'].max}`;
            } else {
              errorMessage = `O campo ${key} está inválido`;
            }
            
            return errorMessage;
          });
  
        this.message = {
          show: true,
          content: `Campos inválidos no formulário:\n${invalidFields.join('\n')}`,
          type: 'error'
        };
        this.form.enable();
        return;
      }
  
      this.loading = true;
      const formValues = this.form.getRawValue();
      this.message = { show: false, content: null };
  
      const formData = new FormData();
      formData.append('dataEmissao', 
        formValues.dataEmissao instanceof Date 
          ? format(formValues.dataEmissao, 'yyyy-MM-dd')
          : format(parse(formValues.dataEmissao, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
      );
      
      formData.append('dataValidade', 
        formValues.dataValidade 
          ? (formValues.dataValidade instanceof Date 
            ? format(formValues.dataValidade, 'yyyy-MM-dd')
            : format(parse(formValues.dataValidade, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'))
          : null
      );
      formData.append('nome', formValues.nome);
      formData.append('file', formValues.file.files[0]);
      formData.append('observacao', formValues.observacao || '');
      formData.append('atualizadoPor', this.usuario?.id);
      formData.append('cliente', this.documento?.cliente?.id || null);
      formData.append('numero', formValues?.numero || null);
      
      this._documentoService.renovarDocumento(this.documento.id, formData)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (response: any) => {
            this.documento = response.data;
            this.message = {
              type: 'success',
              content: 'Documento renovado com sucesso',
              show: true
            };
            this._messageService.add({ 
              severity: 'success',
              summary: 'Ótimo',
              detail: 'Documento renovado com sucesso',
              key: "app"
            });
            this.loading = false;
            this.form.enable();
            if(this._dinamicDialogRef) {
              this._dinamicDialogRef.close();
            }
            this.cancelar();
          },
          error: (error: any) => {
            this.form.enable();
            this.message = {
              type: 'error',
              content: `Houve um erro ao renovar o documento: ${error?.message}`,
              show: true
            };
            this._messageService.add({ 
              severity: 'error',
              summary: 'Erro ao Renovar Documento',
              detail: error?.message,
              sticky: true,
              key: "app"
            });
            this.loading = false;
          }
        });
    } catch (error) {
      console.error(error)
      this.loading = false;
      this.form.enable();
      this.message = {
        type: 'error',
        content: `Houve um erro: ${error?.error?.message}`,
        show: true
      };
      this._messageService.add({ 
        severity: 'error',
        summary: 'Houve um erro',
        detail: error?.error?.message,
        sticky: true,
        key: "app"
      });
    }
  }

  loadDocumentoTipos() {
    try {
      this._documentoTipoService.getSomenteAtivos().pipe(takeUntil(this.destroyed$)).subscribe((data) => {
        this.documentoTipos = data;
      })
    } catch (error) {
      this.message.type = 'error';
      this.message.content = `Houve um erro: ${error?.message}`;
      this.message.show = true;
      this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `${error?.message}`, sticky: true, key: "app" })
    }
  }
  removeFile(file: string) {
    // Lógica para remover o arquivo
    this.documento.file = null;
    this.form.controls.file.setValue(null);
  }
  getSizeFormated(size: number) {
    return this._fileSizePipe.transform(size)
  }
  handleFileChange(event) {
    console.info(event)
  }
  getFileName(file) {
    if (file && file.mimetype && file.mimetype != "") {
      return `${file.filename}.${file.mimetype.split("/")[1]}`
    } else {
      return `${file.id}`
    }
  }
  erroImage(event) {

  }
  cancelar() {
    this._router.navigate([environment.routes[this.contexto].documentos.listar]);
  }
  private configurarModeloDocumento(): void {
    if (!this.form || !this.modelosDocumento?.length) {
      return;
    }
  
    const modelo = this.modelosDocumento[0];
    
    if (!modelo?.validadeDocumentoQuantidade || !modelo?.validadeDocumentoPeriodo) {
      return;
    }
  
    const hoje = new Date();
    
    switch (modelo.validadeDocumentoPeriodo) {
      case 'dia':
        this.dataValidadeMaxima = addDays(hoje, modelo.validadeDocumentoQuantidade);
        break;
      case 'semana':
        this.dataValidadeMaxima = addWeeks(hoje, modelo.validadeDocumentoQuantidade);
        break;
      case 'mes':
        this.dataValidadeMaxima = addMonths(hoje, modelo.validadeDocumentoQuantidade);
        break;
      case 'ano':
        this.dataValidadeMaxima = addYears(hoje, modelo.validadeDocumentoQuantidade);
        break;
    }
  
    this.form.get('dataValidade')?.setValue(this.dataValidadeMaxima);
    this.form.get('dataValidade')?.setValidators([Validators.required]);
    
    this._cdr.detectChanges();
  }
  alterarNomePorModelo() {
    if (this.modelosDocumento.length > 0) {
      this.form.controls.nome.setValue(this.modelosDocumento[0].nome);
    }
  }
}