import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Fornecedor from '@shared/models/fornecedor.interface';
import { Usuario } from '@shared/models/usuario.interface';
import { CotacoesService } from '@shared/services/cotacoes.service';
import { HelpCenterService } from '@shared/services/help-center.service';
import { UserService } from '@shared/services/user.service';
import {
  ApexAxisChartSeries,
  ApexChart, ApexDataLabels, ApexFill, ApexPlotOptions, ApexStroke, ApexYAxis, ChartComponent
} from "ng-apexcharts";
import { EMPTY, forkJoin, of, Subject, timeout } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { UTILS_DASHBOARD } from './utils_dashboard';



export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: any; //ApexXAxis;
  fill: ApexFill;
  stroke: ApexStroke;
  colors: string[];
  labels?: string[]; // Adicione esta linha
};
@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {
  user: Usuario;
  private destroyed$ = new Subject();
  public chartOptionsItensVendidos: Partial<ChartOptions>;
  dataChartOptionsItensVendidos: Array<any> = [];
  public chartOptionsItensAbertos: Partial<ChartOptions>;
  dataChartOptionsItensAbertos: Array<any> = [];
  public chartOptionsItensFechados: Partial<ChartOptions>;
  dataChartOptionsItensFechados: Array<any> = [];
  ftimes: number = 0;
  localItensVendidos: any;
  localItensAbertos: any;
  localItensFechados: any
  empresaSelected: Fornecedor;
  isLoading: Boolean = false;
  lastPostUnread: any = null;

  @ViewChild("chart_itens_vendidos") chart_itens_vendidos: ChartComponent;
  @ViewChild("chart_itens_abertos") chart_itens_abertos: ChartComponent;
  @ViewChild("chart_itens_fechados") chart_itens_fechados: ChartComponent;

  constructor(
    private cotacoesService: CotacoesService,
    private _userService: UserService,
    private _helpCenterSevice: HelpCenterService,
  ) {
    // Dados iniciais
    const dadosIniciais = {
      series: [{
        name: "Quantidade",
        data: []
      }],
      chart: {
        height: 350,
        type: "bar" as "bar",
        animations: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [],
        labels: {
          rotate: -45
        },
        title: {
          style: {
            fontWeight: 700
          }
        }
      },
      yaxis: {
        min: 0
      },
      colors: ['#036637', '#e84f27', '#8990a0']
    };

      // Inicialização dos charts com dados vazios
    this.chartOptionsItensVendidos = { ...dadosIniciais };
    this.chartOptionsItensAbertos = { ...dadosIniciais };
    this.chartOptionsItensFechados = { ...dadosIniciais };

    this.dataChartOptionsItensVendidos = [];
    this.dataChartOptionsItensAbertos = [];
    this.dataChartOptionsItensFechados = [];
    this._userService.getUserData().subscribe((data) => {
      this.user = data as Usuario;
      this._helpCenterSevice.getUnreadPost(this.user.id).subscribe((res: any) => {
        this.lastPostUnread = res.data[0];
      })
      this.empresaSelected = this.user!.fornecedores[0];1
      this.newSelection({ value: this.empresaSelected });
    })
    this.localItensVendidos = localStorage.getItem("dataChartOptionsItensVendidos");
    if (this.localItensVendidos) {
      this.chartOptionsItensVendidos = this.chartBuilder("Quantidade", "Clientes",  JSON.parse(this.localItensVendidos));
    }
    this.localItensAbertos = localStorage.getItem("dataChartOptionsItensAbertos");
    if (this.localItensAbertos) {
      this.chartOptionsItensAbertos = this.chartBuilder("Quantidade", "Clientes", JSON.parse(this.localItensAbertos));
    }
    this.localItensFechados = localStorage.getItem("dataChartOptionsItensFechados");
    if (this.localItensFechados) {
      this.chartOptionsItensFechados = this.chartBuilder("Quantidade", "Clientes", JSON.parse(this.localItensFechados));
    }
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
  newSelection(event) {
    this.isLoading = false;
    this.dataChartOptionsItensVendidos = [];
    this.dataChartOptionsItensAbertos = [];
    this.dataChartOptionsItensFechados = [];
    localStorage.removeItem("dataChartOptionsItensVendidos");
    localStorage.removeItem("dataChartOptionsItensAbertos");
    localStorage.removeItem("dataChartOptionsItensFechados");
    this.destroyed$.next(null);
    this.destroyed$.complete();
    this.destroyed$.unsubscribe();
    this.destroyed$ = new Subject();
    this.loadData(event);
  }
  
    loadData(event) {
    const cnpj = event?.value?.rawCNPJ;
    this.isLoading = true;
    this.resetChartData();
  
    // Obter dados do endpoint
    const requests = this.empresaSelected.clientesAutorizados.map(cliente => 
      this.cotacoesService
        .ExecutarConsultaGeralDashboard(
          Number(cliente.rotina_geral_dashboard), 
          cliente.key, 
          cnpj, 
          "CNPJ"
        )
        .pipe(
          timeout(30000),
          map(res => ({ 
            cliente: cliente,
            res: res || []
          })),
          catchError(error => {
            console.error(`Erro na requisição:`, error);
            return of({ cliente: cliente, res: [] });
          })
        )
    );
  
    // Processar resultados
    forkJoin(requests)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: (results: any) => {
          if (!results?.length) {
            this.resetChartData();
            return;
          }
  
          // Processar dados para cada gráfico
          results.forEach(({ cliente, res }) => {
            if (!cliente || !res?.length) return;
  
            const xlabel = cliente.clienteDesc?.split(" ", 1)[0] || 'Empresa';
  
            // Vendidos - Total de itens com Status "Ordem de Compra"
            const vendidos = res.filter(item => item.Status === "Ordem de Compra");
            this.dataChartOptionsItensVendidos.push({
              x: xlabel,
              y: vendidos.length || 0
            });
  
            // Abertos - Cotações únicas com Status "Cotação Aberta"
            const abertos = res.filter(item => item.Status === "Cotação Aberta");
            const abertosUnicos = [...new Set(abertos.map(item => item.Num_Cot))];
            this.dataChartOptionsItensAbertos.push({
              x: xlabel,
              y: abertosUnicos.length || 0
            });
  
            // Fechados - Cotações únicas com Status "Ordem de Compra"
            const fechados = res.filter(item => item.Status === "Ordem de Compra");
            const fechadosUnicos = [...new Set(fechados.map(item => item.Num_Cot))];
            this.dataChartOptionsItensFechados.push({
              x: xlabel,
              y: fechadosUnicos.length || 0
            });
          });
  
          // Atualizar gráficos
          this.chartOptionsItensVendidos = this.chartBuilder("Quantidade", "Clientes", this.dataChartOptionsItensVendidos);
          this.chartOptionsItensAbertos = this.chartBuilder("Quantidade", "Clientes", this.dataChartOptionsItensAbertos);
          this.chartOptionsItensFechados = this.chartBuilder("Quantidade", "Clientes", this.dataChartOptionsItensFechados);
  
          // Salvar no localStorage
          this.saveToLocalStorage();
        },
        error: (err) => {
          console.error('Erro ao processar dados:', err);
          this.resetChartData();
        },
        complete: () => {
          this.isLoading = false;
        }
      });
  }
  
  private resetChartData() {
    this.dataChartOptionsItensVendidos = [];
    this.dataChartOptionsItensAbertos = [];
    this.dataChartOptionsItensFechados = [];
    
    const emptyData = {
      series: [{
        name: "Quantidade",
        data: []
      }]
    };
    
    this.chartOptionsItensVendidos = this.chartBuilder("Quantidade", "Clientes",  []);
    this.chartOptionsItensAbertos = this.chartBuilder("Quantidade", "Clientes",  []);
    this.chartOptionsItensFechados = this.chartBuilder("Quantidade", "Clientes",  []);
  }

  private processResults(results: any[]) {
    try {
      if (!results?.length) return;
  
      results.forEach(({ cliente, res }) => {
        if (!cliente || !res) return;
  
        // Validação e valor padrão para desc_emp
        const xlabel = cliente?.clienteDesc ? 
          cliente.clienteDesc.split(" ", 1)[0] : 
          '';
        
        this.processChartData(xlabel, res);
      });
  
      this.updateCharts();
      this.saveToLocalStorage();
    } catch (error) {
      console.error('Erro ao processar resultados:', error);
      this.resetChartData();
    }
  }

    private processChartData(xlabel: string, res: any[]): void {
    try {
      // Itens Vendidos - Ordens de Compra
      const vendidos = res.filter(item => item.Status === "Ordem de Compra");
      this.dataChartOptionsItensVendidos.unshift({
        x: xlabel,
        y: vendidos.length || 0
      });
  
      // Cotações em Aberto
      const abertos = res.filter(item => item.Status === "Cotação Aberta");
      const abertosUnicos = UTILS_DASHBOARD.toSetSize(abertos, "Num_Cot");
      this.dataChartOptionsItensAbertos.unshift({
        x: xlabel,
        y: abertosUnicos.length || 0
      });
  
      // Cotações Fechadas - Também Ordens de Compra mas considerando Num_Cot único
      const fechados = res.filter(item => item.Status === "Ordem de Compra");
      const fechadosUnicos = UTILS_DASHBOARD.toSetSize(fechados, "Num_Cot");
      this.dataChartOptionsItensFechados.unshift({
        x: xlabel,
        y: fechadosUnicos.length || 0
      });
  
    } catch (error) {
      console.error('Erro ao processar dados do gráfico:', error);
    }
  }
  
  private updateCharts(): void {
    try {
      this.chartOptionsItensVendidos = this.chartBuilder(
        "Quantidade", "Clientes", 
        this.dataChartOptionsItensVendidos
      );
      
      this.chartOptionsItensAbertos = this.chartBuilder(
        "Quantidade", "Clientes", 
        this.dataChartOptionsItensAbertos
      );
      
      this.chartOptionsItensFechados = this.chartBuilder(
        "Quantidade", "Clientes", 
        this.dataChartOptionsItensFechados
      );
    } catch (error) {
      console.error('Erro ao atualizar gráficos:', error);
      this.resetChartData();
    }
  }
  
  private saveToLocalStorage(): void {
    try {
      if (this.dataChartOptionsItensVendidos?.length) {
        localStorage.setItem(
          "dataChartOptionsItensVendidos",
          JSON.stringify(this.dataChartOptionsItensVendidos)
        );
      }
  
      if (this.dataChartOptionsItensAbertos?.length) {
        localStorage.setItem(
          "dataChartOptionsItensAbertos",
          JSON.stringify(this.dataChartOptionsItensAbertos)
        );
      }
  
      if (this.dataChartOptionsItensFechados?.length) {
        localStorage.setItem(
          "dataChartOptionsItensFechados",
          JSON.stringify(this.dataChartOptionsItensFechados)
        );
      }
    } catch (error) {
      console.error('Erro ao salvar no localStorage:', error);
    }
  }

  private handleError(error: any) {
    console.error('Erro ao carregar dados:', error);
    this.isLoading = false;
    this.resetChartData();
  }

  private chartBuilder(title:string,titleX: string, seriesData: Array<any>): Partial<ChartOptions> {
    return {
      series: [
        {
          name: "Quantidade",
          data: seriesData
        }
      ],
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
          distributed: true,
          dataLabels: {
            position: "top"
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },
      xaxis: {
        title: {
          text: titleX,
          style: {
            fontSize: '14px',
            fontWeight: 700
          }
        },
        labels: {
          rotate: -45
        }
      },
      yaxis: {
        title: {
          text: title,
          style: {
            fontSize: '14px',
            fontWeight: 700
          }
        },
        max: function (max) {
          return max + seriesData.length
        }
      },
      colors: [
        '#036637',
        '#e84f27',
        '#8990a0',
        '#89ba30',
        '#244293',
        '#f2d31d',
        '#132241',
        '#f4a01c',
      ],
      // fill: {
      //   colors: ['#036637','#244293','#f2d31d','#132241'],
      //   opacity: 0.9,
      //   type: 'solid',
      // }
    }
  };
}
