import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Cliente } from '@shared/models/cliente.interface';
import Fornecedor from '@shared/models/fornecedor.interface';
import { RequisicaoDocumento } from '@shared/models/requisicaoDocumento';
import { TreoMessage } from '@shared/models/treoMessage.interface';
import { Usuario } from '@shared/models/usuario.interface';
import { DocumentoTiposService } from '@shared/services/documentoTipos.service';
import { FornecedorService } from '@shared/services/fornecedor.service';
import { RequisicaoDocumentoService } from '@shared/services/requisicaoDocumento.service';
import { UserService } from '@shared/services/user.service';
import { dataInicioMenorQueDataFinalValidator } from '@shared/validators/dataInicioMenorQueDataFinalValidator';

import { format, formatISO, parse } from 'date-fns';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'app-criar-editar-requisicao-documento',
  templateUrl: './criar-editar-requisicao-documento.component.html',
  styleUrls: ['./criar-editar-requisicao-documento.component.scss'],
})
export class CriarEditarRequisitacaoDocumentoComponent implements OnInit,OnDestroy {
  requisicaoDocumento: RequisicaoDocumento;
  documento = null;
  documentoTipos: any[] = [];   
  private destroyed$ = new Subject();
  user: Usuario;
  selectedFile;
  uploadedFiles = [];
  loading: boolean = false;
  results: any[];
  display: boolean = false;
  formData: FormGroup;
  fornecedor: Fornecedor;
  fornecedores: Fornecedor[];
  cliente: Cliente;
  inRoute: boolean = false;
  message: TreoMessage = {
    show: false,
    appearance: 'outline',
    showIcon: true,
  };
  role: string;
  prazo: any;
  contarDiasDeHojeAteInicio: number;

  constructor(
    private _formBuilder: FormBuilder,
    private _requisicaoDocumentoService: RequisicaoDocumentoService,
    private _documentoTipoService: DocumentoTiposService,
    private _userService: UserService,
    private _messageService: MessageService,
    private _fornecedorService: FornecedorService,
    private _activatedRoute: ActivatedRoute,
    private _config: DynamicDialogConfig,
    private _dinamicDialogRef: DynamicDialogRef,
    ) {
        this.role = this._activatedRoute.snapshot.data.role;
        if(this._config.data && (this._config.data.create == true || this._config.data.requisicaoDocumento)) {
          this.requisicaoDocumento = this._config.data.requisicaoDocumento;
        }else{
          this.inRoute = true; // senao tem dado do dialoag é rota
          if(this._activatedRoute.snapshot.data.requisicaoDocumento) {
            this.requisicaoDocumento = this._activatedRoute.snapshot.data.requisicaoDocumento;
          }
        }
      this._userService.getUserData().subscribe((user:Usuario)=>{
        this.user = user;
        this.cliente = this.user.clienteComprador as Cliente;
        this.loadFornecedores();
    })      
  }

  loadFornecedores(event = {}){
    event['filters'] = "";
    this.loading = true;
    this._fornecedorService.listarTodosFornecedorPorCliente(this.cliente.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: any) => {
        this.loading = false
        this.fornecedores = res.data;
      },()=>{
        this.loading = false;
        this.fornecedores = []
      })
  }

  search(event) {
    
    const searchTerm = event.query.toLowerCase();
    this.results = this.documentoTipos.filter(item => {
      return item.toLowerCase().includes(searchTerm)
    })
  }

  ngOnInit(): void {
    this._userService.getUserData().pipe(takeUntil(this.destroyed$)).subscribe((data: Usuario) => {
      this.user = data;

      if(this.formData){
        this.formData.get('cliente').patchValue(this.user.clienteComprador.id)
        this.formData.get('solicitadoPor').patchValue(this.user.id)
      }
    })
    this.loadDocumentoTipos()
    
    this.createForm(this.requisicaoDocumento)
  }
  ngOnDestroy(): void {
    this.destroyed$.unsubscribe()
  }
  createForm(requisicao: RequisicaoDocumento) {
    if(requisicao){
      let fornecedor;
      if(Array.isArray(this.fornecedores)){
        fornecedor = requisicao.fornecedorRequerido.map((e)=> e.id)
      }else{
        fornecedor = [requisicao.fornecedorRequerido.id];
      }
      this.formData = this._formBuilder.group({
        dataInicio: [(requisicao.dataInicio ? format(new Date(requisicao.dataInicio),'yyyy-MM-dd') : new Date().toISOString()), Validators.required],
        dataFinal: [(requisicao.dataFinal ? format(new Date(requisicao.dataFinal),'yyyy-MM-dd') : null), requisicao.dataFinal ? [Validators.required] : []],
        tipo: [(requisicao.tipo.id || null), Validators.required],
        motivo: [(requisicao.motivo || ''), Validators.required],
        fornecedorRequerido: [(fornecedor || null), Validators.required],
        observacao: [(requisicao.observacao ||'')],
        solicitadoPor: [(this.user ? this.user.id : null)],
        cliente: [(this.cliente ? this.cliente.id : null)],
      }, {
        validators: dataInicioMenorQueDataFinalValidator('dataInicio', 'dataFinal')
      });
    }else{
      this.formData = this._formBuilder.group({
        dataInicio: [formatISO(new Date(), { representation: 'date' }), Validators.required],
        dataFinal: [null],
        tipo: [null, Validators.required],
        motivo: ['', Validators.required],
        fornecedorRequerido: [[], Validators.required],
        observacao: [''],
        solicitadoPor: [this.user ? this.user.id : null],
        cliente: [this.cliente ? this.cliente.id : null],
      }, {
        validators: dataInicioMenorQueDataFinalValidator('dataInicio', 'dataFinal')
      });
    }
  }
  
  loadDocumentoTipos() {
      this.message.show = false;
      try {
      this._documentoTipoService.getSomenteAtivos(this.cliente.id).pipe(takeUntil(this.destroyed$)).subscribe((data) => {
        this.documentoTipos = data;
      })
    } catch (error) {
      this.message.content = `Houve um erro: ${error?.message}`
      this.message.type = "error";
      this.message.show = true;
      this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `${error?.message}`, sticky: true, key: "app" })
    }
  }
  
  saveData() {
    
      try {
      this.formData.markAllAsTouched();
      if (!this.formData.valid) {
        return;
      }
      this.message.show = false;
      this.loading = true;
      const formValues = this.formData.getRawValue();
      if(formValues.dataFinal && formValues.dataFinal != ""){
        const dataParseInicio = parse(formValues.dataInicio, 'yyyy-MM-dd', new Date());
        formValues.dataInicio = format(dataParseInicio, "yyyy-MM-dd'T'HH:mm:ss.sss'Z'")
      }
      if(formValues.dataFinal && formValues.dataFinal != ""){
        const dataParseFinal = parse(formValues.dataFinal, 'yyyy-MM-dd', new Date());
        formValues.dataFinal = format(dataParseFinal, "yyyy-MM-dd'T'HH:mm:ss.sss'Z'");
      }
      if(this.requisicaoDocumento && this.requisicaoDocumento.id){
        this._requisicaoDocumentoService.updateDocument(this.requisicaoDocumento.id,formValues).pipe(takeUntil(this.destroyed$)).subscribe(
          (response: any) => {
            this.documento = response.data;
            this.message.content = `Requisiçāo foi salva com sucesso`
            this.message.type = "success";
            this.message.show = true;
            this._messageService.add({ severity: 'success', summary: 'Ótimo', detail: `Requisiçāo foi salva com sucesso` , key: "app" })
            
            this.loading = false;
            this.formData.reset()
            this._dinamicDialogRef.close()
          },
          (error: any) => {
            this.message.content = `Houve um erro: ${error?.message}`
            this.message.type = "error";
            this.message.show = true;
            this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `${error?.message}` , sticky: true, key: "app" })
            console.error(error);
            this.loading = false;
          }
        );
      }else{
        this._requisicaoDocumentoService.createDocument(formValues).pipe(takeUntil(this.destroyed$)).subscribe(
          (response: any) => {
            this.documento = response.data;
            this.message.content = `Requisiçāo foi salva com sucesso`
            this.message.type = "success";
            this.message.show = true;
            this._messageService.add({ severity: 'success', summary: 'Ótimo', detail: `Requisiçāo foi salva com sucesso` , key: "app" })
            
            this.loading = false;
            this.formData.reset()
            this._dinamicDialogRef.close()
          },
          (error: any) => {
            this.message.content = `Houve um erro: ${error?.message}`
            this.message.type = "error";
            this.message.show = true;
            this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `${error?.message}`, sticky: true, key: "app" })
            
            this.loading = false;
          }
        );
      }

    } catch (error) {
      this.loading = false;
      this.message.content = `Houve um erro: ${error?.message}`
      this.message.type = "error";
      this.message.show = true;
      this._messageService.add({ severity: 'error',life: 0,closable: true, summary: 'Houve um erro', detail: `${error?.message}`, sticky: true, key: "app" })
    }
  }
  cancelar(){
    this.requisicaoDocumento = null;
    this.formData.reset();
    this._dinamicDialogRef.close()
    // if(this.user.roleSelected == "comprador"){
    //   return this._router.navigate([environment.routes.comprador.documentos.listar])
    // }
    // return this._router.navigate(['..'])
  }
  apresentarMensagemDataInicio(){
    if( this.formData && 
      this.formData.controls.dataInicio.value != null && 
      this.formData.controls.dataInicio.value != "" 
    ){
      return true;
    }else{
      return false 
    }
  }
  apresentarMensagemDataFinal(){
    if( this.formData && 
      this.formData.controls.dataFinal.value != null && 
      this.formData.controls.dataFinal.value != "" 
    ){
      return true;
    }else{
      return false 
    }
  }
}
