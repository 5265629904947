import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModeloDocumentoAlertComponent } from './modelo-documento-alert.component';
import { MaterialModule } from '@shared/material/material.module';
import { TreoMessageModule } from '@treo/components/message';
import { SharedModule } from 'primeng/api';
import { TreoCardModule } from '@treo/components/card';

@NgModule({
  declarations: [
    ModeloDocumentoAlertComponent,
  ],
  exports: [
    ModeloDocumentoAlertComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    TreoMessageModule,
    TreoCardModule,
    
  ]
})
export class ModeloDocumentoAlertModule { }
