export const environment = {
  firebase: {
    projectId: 'sistemacotar',
    appId: '1:530093175720:web:528418ce7843af6960a39d',
    storageBucket: 'sistemacotar.appspot.com',
    apiKey: 'AIzaSyBtvolnrx9M-Nu44kr1_RCqCtYTsvigNHY',
    authDomain: 'sistemacotar.firebaseapp.com',
    messagingSenderId: '530093175720',
    measurementId: 'G-MDW2JL39NK',
  },
  production: false,
  apiEndpoint: 'https://cotarcloud.com.br/api/',
  hostname: 'cotarcloud.com.br',
  enableDebug: false,
  workflow: {
    header: {
     name: 'X-N8N-API-KEY',
     value: 'n8n_api_05b971f1c7174d6130160176634478bddb3adcb8471b3634ce567a1c0f59e8509b31d19dcc2d0163',
    },
    endoint: 'https://workflow.cotarcloud.com.br/webhook',
    urls: {
      testarEnvioDeEmail: `14e468dd-07fc-40cf-89f1-05cb2f744d58`,
      consultarSeUmFornecedorEstaCadastradoNosClientes: 'b0f64d14-cb45-40a1-8c31-14b9cb09ce3a'
    }
  },
  cookie: {
    authName: 'access_token',
    auth_domain: '.cotarcloud.com.br',
    auth_path: '/'
  },
  routes: {
    auth: {
      forgotPassword: "/auth/forgot-password",
      resetPassword: "/auth/reset-password",
      login: "/auth/login",
      logout: "/auth/logout",
      cadastrar: "/auth/cadastrar",
    },
    signedInRedirect: {
      vendedor: "/vendedor/dashboard",
      comprador: "/comprador/dashboard",
      backoffice: "/backoffice/dashboard",
    },
    vendedor: {
      conta: "/vendedor/minhaconta",
      dashboard: "/vendedor/dashboard",
      cotacoes: {
        listar: "/vendedor/cotacoes/listar",
        mostrar: "/vendedor/cotacoes/mostrar",
        preencher: "/vendedor/cotacoes/preencher/cotacao",
      },
      fornecedores: {
        listar: "/vendedor/empresas/listar",
        cadastrar: "/vendedor/empresas/cadastrar",
        editar: "/vendedor/empresas/editar",
      },
      helpcenter: {
        home: "/vendedor/help-center",
        faqs: "/vendedor/help-center/faqs",
        guides: "/vendedor/help-center/guides",
        posts: "/vendedor/help-center/posts",
        suporte: "/vendedor/help-center/suporte",
      },
      documentos: {
        listar: "/vendedor/documentos/listar",
        cadastrar: "/vendedor/documentos/cadastrar",
        requisitar: "/vendedor/documentos/requisitar",
        editar: "/vendedor/documentos/editar",
        renovar: "/vendedor/documentos/renovar",
      },
      modeloDocumento: {
        listar: "/vendedor/modelo-documento/listar",
        cadastrar: "/vendedor/modelo-documento/cadastrar",
        editar: "/vendedor/modelo-documento/editar",
        exibir: "/vendedor/modelo-documento/exibir",
      }
    },
    backoffice: {
      conta: "/backoffice/minhaconta",
      dashboard: "/backoffice/dashboard",
      clientes: {
        listar: "/backoffice/clientes/listar",
        cadastrar: "/backoffice/clientes/cadastrar",
        editar: "/backoffice/clientes/editar",
      },
      usuarios: {
        listar: "/backoffice/usuarios/listar",
        cadastrar: "/backoffice/usuarios/cadastrar",
        editar: "/backoffice/usuarios/editar",
      },
      fornecedores: {
        listar: "/backoffice/fornecedores/listar",
        cadastrar: "/backoffice/fornecedores/cadastrar",
        editar: "/backoffice/fornecedores/editar",
      },
      modeloDocumento: {
        listar: "/backoffice/modelo-documento/listar",
        cadastrar: "/backoffice/modelo-documento/cadastrar",
        editar: "/backoffice/modelo-documento/editar",
        exibir: "/backoffice/modelo-documento/exibir",
      },
      documentos: {
        listar: "/backoffice/documentos/listar",
        cadastrar: "/backoffice/documentos/cadastrar",
        requisitar: "/backoffice/documentos/requisitar",
        editar: "/backoffice/documentos/editar",
        renovar: "/backoffice/documentos/renovar",
      },
      localizar_fornecedores: {
        listar: "/backoffice/localizar-fornecedores/listar"
      }
    },
    comprador: {
      conta: "/comprador/minhaconta",
      dashboard: {
        listar: "/comprador/dashboard",
        informacoesGeral: "/comprador/dashboard/parcial/geral",
        cicloDeCompras: "/comprador/dashboard/parcial/compras",
      },
      vendedores: {
        listar: "/comprador/vendedores/listar",
        cadastrar: "/comprador/vendedores/cadastrar",
        editar: "/comprador/vendedores/editar",
        exibir: "/comprador/vendedores/exibir",
      },
      fornecedores: {
        listar: "/comprador/fornecedores/listar",
        cadastrar: "/comprador/fornecedores/cadastrar",
        editar: "/comprador/fornecedores/editar",
        ativos: "/comprador/fornecedores/ativos",
        pendentes: "/comprador/fornecedores/pendentes",
      },
      documentos: {
        listar: "/comprador/documentos/listar",
        cadastrar: "/comprador/documentos/cadastrar",
        requisitar: "/comprador/documentos/requisitar",
        editar: "/comprador/documentos/editar",
      },
      helpcenter: {
        home: "/comprador/help-center",
        faqs: "/comprador/help-center/faqs",
        guides: "/comprador/help-center/guides",
        posts: "/comprador/help-center/posts",
        suporte: "/comprador/help-center/suporte",
      },
      modeloDocumento: {
        listar: "/comprador/modelo-documento/listar",
        cadastrar: "/comprador/modelo-documento/cadastrar",
        editar: "/comprador/modelo-documento/editar",
        exibir: "/comprador/modelo-documento/exibir",
        renovar: "/comprador/documentos/renovar",
      },
      cotacao: {
        home: "/comprador/cotacao",
        listar: "/comprador/cotacao/listar",
        abertas: "/comprador/cotacao/abertas",
        historicas: "/comprador/cotacao/historicas",
        exibir: "/comprador/cotacao/exibir",
        convidar: "/comprador/cotacao/convidar",
        adicionarFornecedor: "/comprador/cotacao/adicionar-fornecedor",
      },
      aprovacao: {
        home: "/comprador/aprovacao/listar",
        cotacaoPendente: "/comprador/aprovacao/cotacao-pendente",
        exibir: "/comprador/aprovacao/exibir",
        cotacaoItemsPendentes: "/comprador/aprovacao/listar/cotacao-pendente/items",
        mapaDeCotacao: "/comprador/aprovacao/listar/mapa-de-cotacao",
        mapaDeCotacaoItems: "/comprador/aprovacao/listar/mapa-de-cotacao/items",
        mapaDeCotacaoFornecedores: "/comprador/aprovacao/listar/mapa-de-cotacao/fornecedores",
      },
    },
    errors: {
      error404: '/error/404',
      error403: '/error/403',
      error500: '/error/500',
    }
  },
  sentry: {
    dsn: 'https://b938a4371a404ffaa02d9ad5e61abbb4@o4504684602392576.ingest.us.sentry.io/4505126490996736'
  },
  permissaoBetaAprovacao: ["6202be980df89f182e57fdb2","6009efb744fc2d001c33c970","66172433de0b2b44bc87faf3"]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.