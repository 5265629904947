import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DadosBancariosUAU } from '@shared/models/dadosBancariosUAU.interface';
import Fornecedor, { Endereco } from '@shared/models/fornecedor.interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FornecedorService {

  readonly SERVER_URL = environment.apiEndpoint;
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  getAllSimple(): Observable<any> {
    return this._httpClient.get<any>(`${this.SERVER_URL}fornecedor/getall`);
  }

  listarFornecedor(event): Observable<any> {
    event.situacao = 'ativa';
    delete event.forceUpdate;
    event.filters = JSON.stringify(event.filters);
    const options = { params: event };
    return this._httpClient.get<any>(`${this.SERVER_URL}fornecedor/list`, options);
  }
  listarFornecedorPorCliente(event, cliente: string): Observable<any> {
    event.situacao = 'ativa';
    delete event.forceUpdate;
    event.filters = JSON.stringify(event.filters);
    const options = { params: event };
    return this._httpClient.get<any>(`${this.SERVER_URL}fornecedor/list/${cliente}/cliente`, options);
  }
  listarTodosFornecedorPorCliente( cliente: string): Observable<any> {
    return this._httpClient.get<any>(`${this.SERVER_URL}fornecedor/list/${cliente}/cliente/all`);
  }
  listarFornecedorPorVendedor(event, vendedor: string ): Observable<any> {
    event.situacao = 'ativa';
    delete event.forceUpdate;
    event.filters = JSON.stringify(event.filters);
    const options = { params: event };
    return this._httpClient.get<any>(`${this.SERVER_URL}fornecedor/list/${vendedor}/vendedor`, options);
  }
  getOneFornecedor(id: string): Observable<any> {
    return this._httpClient.get<any>(`${this.SERVER_URL}fornecedor/${id}`);
  }
  criarFornecedor(rawCNPJ: string): Observable<any> {
    return this._httpClient.post<any>(`${this.SERVER_URL}fornecedor`, { rawCNPJ });
  }
  criarFornecedorPeloVendedor(vendedorId,rawCNPJ: string): Observable<any> {
    return this._httpClient.post<any>(`${this.SERVER_URL}fornecedor`, { rawCNPJ, vendedorId });
  }
  vincularVendedorFornecedor(vendedorId, cnpjFornecedor) {
    const body = { vendedorId: vendedorId, cnpjFornecedor: cnpjFornecedor }
    return this._httpClient.patch(`${this.SERVER_URL}fornecedor/vincular`,body  );
  }
  desvincularVendedorFornecedor(vendedorId, cnpjFornecedor) {
    const body = { vendedorId: vendedorId, cnpjFornecedor: cnpjFornecedor }
    return this._httpClient.patch(`${this.SERVER_URL}fornecedor/desvincular`, body);
  }
  atualizarFornecedor(fornecedor: Fornecedor): Observable<any> {
    return this._httpClient.patch<any>(`${this.SERVER_URL}fornecedor`, fornecedor);
  }
  atualizarFornecedorProspect(fornecedor: Fornecedor): Observable<any> {
    return this._httpClient.patch<any>(`${this.SERVER_URL}prospect/fornecedor`, fornecedor);
  }
  removerFornecedor(id: string): Observable<any> {
    return this._httpClient.delete<any>(`${this.SERVER_URL}fornecedor/${id}`);
  }

  consultarCNPJ(cnpjSemFormatacao: string) {
    return this._httpClient.get(`${this.SERVER_URL}fornecedor/consultar/${cnpjSemFormatacao}`);
  }
  consultarEhPersistirCNPJ(cnpjSemFormatacao: string,cliente_key: string = null) {
    return this._httpClient.post(`${this.SERVER_URL}fornecedor/persistir`,{"cnpj": cnpjSemFormatacao, "cliente_key": cliente_key });
  }
  consultarEhPersistirCNPJProspect(cnpjSemFormatacao: string,cliente_key: string = null) {
    return this._httpClient.post(`${this.SERVER_URL}prospect/fornecedor/persistir`,{"cnpj": cnpjSemFormatacao, "cliente_key": cliente_key });
  }
  
  consultarEmailEhVincular(cnpjSemFormatacao: string,email: string) {
    return this._httpClient.post(`${this.SERVER_URL}fornecedor/validarEhVincular`,{"cnpj": cnpjSemFormatacao,"email": email });
  }
  consultarEmailEhVincularProspect(cnpjSemFormatacao: string,email: string) {
    return this._httpClient.post(`${this.SERVER_URL}prospect/fornecedor/validarEhVincular`,{"cnpj": cnpjSemFormatacao,"email": email });
  }
  
  loadVendedoresComAcesso(id: string, event) {
    const options = { params: JSON.parse(JSON.stringify(event)) };
    return this._httpClient.get<any>(`${this.SERVER_URL}fornecedor/${id}/vendedores`, options);
  }

  /**
   * Retrieves a list of authorized suppliers from a specific client.
   * 
   * @param clienteId - The ID of the client.
   * @param status - The status of the authorized suppliers.
   * @returns A Promise that resolves to the HTTP response containing the list of authorized suppliers.
   */
  getListAutorizadosFromCliente(clienteId: string, status: string, event: any): Observable<any> {
    delete event.forceUpdate;
    event.filters = JSON.stringify(event.filters);
    const options = { params: event };
    return this._httpClient.get<any>(`${this.SERVER_URL}fornecedor/list/cliente/${clienteId}/autorizados/${status}`, options);
  }

  /**
   * Retrieves a list of non-authorized suppliers from a specific client.
   * 
   * @param clienteId - The ID of the client.
   * @param status - The status of the suppliers (e.g., "active", "inactive").
   * @returns A Promise that resolves to the HTTP response containing the list of non-authorized suppliers.
   */
  getListNaoAutorizadosFromCliente(clienteId: string, status: string,event) {
    delete event.forceUpdate;
    event.filters = JSON.stringify(event.filters);
    const options = { params: event };
    return this._httpClient.get(`${this.SERVER_URL}fornecedor/list/cliente/${clienteId}/naoAutorizados/${status}`,options);
  }

  /**
   * Retrieves a list of authorized suppliers based on the specified status.
   * 
   * @param status - The status of the suppliers to retrieve.
   * @returns A Promise that resolves to the HTTP response containing the list of authorized suppliers.
   */
  getListAllAutorizado(status: string,event) {
    const options = { params: JSON.parse(JSON.stringify(event)) };
    return this._httpClient.get(`${this.SERVER_URL}fornecedor/list/all/autorizados/${status}`,options);
  }

  /**
   * Retrieves a list of all non-authorized suppliers based on the specified status.
   *
   * @param status - The status of the suppliers to retrieve.
   * @returns A Promise that resolves to the HTTP response containing the list of non-authorized suppliers.
   */
  getListAllNaoAutorizados(status: string,event) {
    const options = { params: JSON.parse(JSON.stringify(event)) };
    return this._httpClient.get(`${this.SERVER_URL}fornecedor/list/all/naoAutorizados/${status}`,options);
  }
  
  gravarFornecedor(clienteId: string, fornecedorId: string): Observable<any> {
    return this._httpClient.get<any>(`${this.SERVER_URL}fornecedor/cliente/${clienteId}/gravar/${fornecedorId}`);
  }

  atualizarDadoBancarioNoCliente(clienteId: string, fornecedorId: string, dadoBancarioUAU: DadosBancariosUAU,origem: string): Observable<any> {
    return this._httpClient.patch<any>(`${this.SERVER_URL}/fornecedor/atualizar/dadoBancario`,{dadoBancarioUAU,clienteId,fornecedorId, origem});
  }
  sincronizarDadosBancariosNosClientes(fornecedor: string): Observable<any> {
    return this._httpClient.patch<any>(`${this.SERVER_URL}/fornecedor/atualizar/sincronizarDadosBancarios`,{fornecedor});
  }
  atualizarEnderecoNoCliente(clienteId: string, fornecedorId: string,indexOfEndereco: number): Observable<any> {
    return this._httpClient.patch<any>(`${this.SERVER_URL}/fornecedor/atualizar/endereco`,{indexOfEndereco,clienteId,fornecedorId});
  }
  updateEndereco(fornecedorId: string,endereco: Endereco){
    return this._httpClient.patch<any>(`${this.SERVER_URL}/fornecedor/atualizar/endereco`,{fornecedorId,endereco});
  }
  sincronizarLocalizacaoDoEndereco(fornecedorId: string,enderecoId: string){
    return this._httpClient.patch<any>(`${this.SERVER_URL}/fornecedor/atualizar/sincronizarLocalizacaoDoEndereco`,{fornecedorId,enderecoId}); 
  }
}
