import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {


  readonly SERVER_URL = environment.apiEndpoint;
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
  ) { }


  // listar(event): Observable<any> {
  //   let options;
  //   if(event){
  //     delete event.forceUpdate;
  //     event.filters = JSON.stringify(event.filters);
  //     options = { params: event };
  //   }
  //   return this._httpClient.get<any>(`${this.SERVER_URL}file/list`, options);
  // }
  // getOne(id): Observable<any> {
  //   return this._httpClient.get<any>(`${this.SERVER_URL}file/${id}`);
  // }
  // upload(file, bucket): Observable<any> {
  //   return this._httpClient.post<any>(`${this.SERVER_URL}file/${bucket}`, file);
  // }
  // uploadProgress(file): Observable<any> {
  //   return this._httpClient.post<any>(`${this.SERVER_URL}file`, file, {
  //     reportProgress: true,
  //     observe: 'events'
  //   });
  // }
  // deleteOne(id): Observable<any> {
  //   return this._httpClient.delete<any>(`${this.SERVER_URL}file/${id}`);
  // }
  urlViewFile(id): string {
    return `${environment.apiEndpoint}file/view/${id}`;
  }
  urlDownloadFile(id): string {
    return `${environment.apiEndpoint}file/download/${id}`;
  }
  
  // Novo método para baixar arquivo em base64 e armazenar no sessionStorage
  async downloadFileBase64(url: string, id: string): Promise<string | null> {
    try {
      const response: Blob = await firstValueFrom(this._httpClient.get(url, { responseType: 'blob' }));
      
      const base64 = await this.convertBlobToBase64(response);
      if (base64) {
        // Armazenar o arquivo em base64 no sessionStorage
        sessionStorage.setItem(`file_${id}`, base64);
        return base64;
      } else {
        console.error('Falha ao converter o blob para base64.');
        return null;
      }
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error);
      return null;
    }
  }

  // Método auxiliar para converter Blob para base64
  private convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result?.toString().split(',')[1];
        resolve(base64data || '');
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }



   // Novo método para recuperar o arquivo do sessionStorage
   getFileFromSessionStorage(id: string): string | null {
    const file = sessionStorage.getItem(`file_${id}`);
    if (file) {
      return file;
    } else {
      console.error(`Arquivo ${id} não encontrado no sessionStorage.`);
      return null;
    }
  }
}
