import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RequisicaoDocumentoService } from '@shared/services/requisicaoDocumento.service';
import { MessageService } from 'primeng/api';
import { EMPTY, Observable, catchError, tap, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequisicaoDocumentoResolver implements Resolve<boolean> {
  constructor(
    private _requisicaoDocumentoService: RequisicaoDocumentoService,
    private _messageService: MessageService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const id = route.params.id;

    if (id === null || id === undefined) {
      this._messageService.add({
        key: "app",
        severity: 'error',
        summary: 'Houve um erro',
        detail: 'é necessário informar um id!',
        sticky: true,
      });
      return EMPTY;
    }

    return this._requisicaoDocumentoService.getDocumentById(id).pipe(
      tap((response) => response),
      catchError((err) => {
        console.error('Erro detalhado:', err);
        this._messageService.add({
          key: "app",
          severity: 'error',
          summary: 'Houve um erro',
          detail: err?.error?.message || 'Erro ao buscar dados da Requisição de Documento',
          sticky: true,
        });
        return of(null); // Retorna null ao invés de EMPTY para evitar erro no resolver
      }));
    }
}