<div [ngClass]="inRoute ? 'content-layout fullwidth-standard-content-scroll' : ''">
  <!-- Header -->
  <div class="header" *ngIf="inRoute">
    <div class="left">
      <h1>{{ 'Exibir Documento: ' + documento.nome+ ' #' + documento.id }}</h1>
    </div>
    <div class="right">
      <!-- Action buttons -->
    </div>
  </div>
  <div class="max-w">
    <form [formGroup]="form" autocomplete="disabled"
      class="flex flex-col p-8 pb-0 bg-card shadow-xl rounded overflow-hidden">
      <div class="flex flex-col gap-4">
        <div class="treo-mat-dense w-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 gap-4">
          <div class="treo-mat-dense flex-auto">
            <mat-form-field class="w-full">
              <mat-label>Fornecedor</mat-label>
              <input matInput [readonly]="true" style="width: 100%;" [ngModel]="documento.fornecedor?.nome" [ngModelOptions]="{standalone: true}" />
            </mat-form-field>
          </div>

          <div class="treo-mat-dense flex-auto">
            <span class="label">Tipo do Documento</span>
            <p-dropdown  [options]="documentoTipos" class="mt-1 block w-full" formControlName="tipo"
              optionLabel="nome" optionValue="id" [editable]="false" [filter]="true" filterBy="nome" [showClear]="false"
              [virtualScroll]="true" [lazy]="true" [virtualScrollItemSize]="60"
              placeholder="Selecione um tipo"></p-dropdown>
          </div>
        </div>

        <div class="treo-mat-dense w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4">
          <mat-form-field>
            <mat-label>Nome do Documento</mat-label>
            <input matInput formControlName="nome" required >
            <mat-error *ngIf="form && form.get('nome').hasError('required')">O campo Nome é obrigatório.</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Número</mat-label>
            <input matInput formControlName="numero" required >
            <mat-error *ngIf="form && form.get('numero').hasError('required')">O campo Número é obrigatório.</mat-error>
          </mat-form-field>
        </div>
        <!-- <div class="treo-mat-dense w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4">
          <mat-form-field>
            <mat-label>Inscricao Municipal</mat-label>
            <input matInput formControlName="inscricaoMunicipal">
            <mat-error *ngIf="form && form.controls.inscricaoMunicipal.hasError('required')">O campo é obrigatório.</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Código de Verificaçāo</mat-label>
            <input matInput formControlName="codigoDeVerificacao" >
            <mat-error *ngIf="form && form.controls.codigoDeVerificacao.hasError('required')">O campo é obrigatório.</mat-error>
          </mat-form-field>
        </div>  -->
        <div class="treo-mat-dense w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4">
          <mat-form-field>
            <mat-label>Data de Emissāo</mat-label>
            <input matInput formControlName="dataEmissao" type="date" required >
            <mat-error *ngIf="form && form.get('dataEmissao').hasError('required')">O campo Data de Emissāo é
              obrigatório.</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Data de Validade</mat-label>
            <input matInput formControlName="dataValidade" type="date" required >
            <mat-error *ngIf="form && form.get('dataValidade').hasError('required')">O campo dataValidade é
              obrigatório.</mat-error>
          </mat-form-field>
        </div>
        <mat-form-field class="treo-mat-dense flex-auto">
          <mat-label>Observaçāo</mat-label>
          <textarea matInput formControlName="observacao" ></textarea>
          <mat-error>O campo Observaçāo é obrigatório.</mat-error>
        </mat-form-field>
        <div class="treo-mat-dense flex-auto">
          <label>Arquivo</label>
          <treo-message class="w-full" [type]="'basic'" [showIcon]="true"> <mat-icon class="mr-2" treoMessageIcon
            [svgIcon]="'insert_drive_file'"></mat-icon>
            <div class="flex items-center justify-between">
              <div class="flex-1">{{ getFileName(documento.file) }}</div>
              <div class="flex"> 
                <button mat-icon-button class="mx-2" (click)="downloadFile()" > <mat-icon>download</mat-icon> </button> 
                <button mat-icon-button class="mx-2" (click)="viewFile()" > <mat-icon>open_in_new</mat-icon> </button> </div>
            </div>
          </treo-message>
        </div>
        <div class="treo-mat-dense flex-auto mb-5">
          <label class="mb-3">Visualizar Arquivo</label>
          <div>
            <ng-container *ngIf="mimeType && mimeType.startsWith('image/')">
              <p-image [src]="urlFile" alt="Image" width="250" [preview]="true" (onImageError)="erroImage($event)" ></p-image>
            </ng-container>
            <ng-container *ngIf="mimeType && mimeType === 'application/pdf'">
              <ngx-extended-pdf-viewer [src]="urlFile" [height]="'600px'" [width]="'100%'" [useBrowserLocale]="true"
                [showToolbar]="false" [originalSize]="false" [minZoomLevel]="0.5" [maxZoomLevel]="3"
                [zoom]="'page-width'"></ngx-extended-pdf-viewer>
            </ng-container>
            <ng-container *ngIf="mimeType && !mimeType.startsWith('image/') && mimeType !== 'application/pdf'">
              <div class="flex flex-col items-center justify-center space-y-2">
                <p>O Documento com extensāo "<strong>{{ (mimeType.split("/")[1]).split(".")[1] }}</strong>" não pode ser visualizado aqui, será necessário realizar o download do arquivo.</p>
                <button pButton pRipple type="button" class="p-button-raised p-button-secondary" (click)="downloadFile()">Fazer o Download do Arquivo</button>
              </div>
            </ng-container>
            <ng-container *ngIf="documento == null">
              <h6 class="text-center">Documento indisponível para visualização.</h6>
            </ng-container>
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>