import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@environments';
import { AuthService } from '@shared/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    private _cookieService: CookieService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // URL de login que deve ser ignorada para o cabeçalho de autorização
    const loginUrl = `${environment.apiEndpoint}login`;

    // Adiciona o cabeçalho Content-Type a todas as requisições
    // let headers = req.headers.set('Content-Type', 'application/json');
    let headers = req.headers;
    // Adiciona o cabeçalho de autorização, exceto para a URL de login
    if (req.url !== loginUrl) {
      const authToken = this._authService.accessToken || this._cookieService.get(environment.cookie.authName);
      headers = headers.set('Authorization', `Bearer ${authToken}`);
    }

    const authReq = req.clone({ headers });

    return next.handle(authReq);
  }
}