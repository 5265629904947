import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class UrlCleanupInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Verifica se a URL começa com o apiEndpoint
    if (req.url.startsWith(environment.apiEndpoint)) {
      // Remove barras duplas consecutivas, exceto após 'http:' ou 'https:'
      const cleanUrl = req.url.replace(/([^:])\/+/g, '$1/');
      
      // Clona a requisição com a URL limpa
      const cleanReq = req.clone({
        url: cleanUrl
      });
      
      return next.handle(cleanReq);
    }
    
    return next.handle(req);
  }
}