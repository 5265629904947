import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule, CONFIG } from '@angular/fire/compat/analytics';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/compat/performance';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, NoPreloading, Router, RouterModule } from '@angular/router';
import { BackofficeDashboardModule } from '@backoffice/backoffice-dashboard/backoffice-dashboard.module';
import * as Sentry from '@sentry/angular-ivy';
import { AuthService } from '@shared/services/auth.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TreoModule } from '@treo';
import { TreoMockApiModule } from '@treo/lib/mock-api';
import { TreoConfigModule } from '@treo/services/config';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { appConfig } from 'app/core/config/app.config';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { MarkdownModule } from 'ngx-markdown';
import { NgxMaskModule } from 'ngx-mask';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { environment } from '../environments/environment';
import { ComponentesModule } from './core/componentes/componentes.module';
import { RoleSelectorModule } from './core/componentes/role-selector/role-selector.module';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { mockDataServices } from './dataAPI/mock';
import { RequisicaoDocumentosModule } from './modules/requisicao-documentos/requisicao-documentos.module';
import { DashboardModule } from './modules/vendedor/dashboard/dashboard.module';
import { MaterialModule } from './shared/material/material.module';
import { FileSizePipe } from './shared/pipes/fileSize.pipe';
import { TruncatePipe } from './shared/pipes/truncate.pipe';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import localePtExtra from '@angular/common/locales/extra/pt';
import { UrlCleanupInterceptor } from './core/interceptors/url-cleanup.interceptor';


registerLocaleData(localePt, 'pt-BR', localePtExtra);

const routerConfig: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  preloadingStrategy: NoPreloading , // PreloadAllModules,
  enableTracing: false,
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
  useHash: false,
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),
    // Treo & Treo Mock API
    TreoModule,
    TreoConfigModule.forRoot(appConfig),
    TreoMockApiModule.forRoot(mockDataServices),
    // Core
    CoreModule,
    // Layout
    LayoutModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    // 3rd party modules
    MarkdownModule.forRoot({}),
    NgxMaskModule.forRoot(),
    MaterialModule,
    SweetAlert2Module.forRoot(),
    // NgxMaskModule.forRoot(),
    SimpleNotificationsModule.forRoot({
      position: ["bottom", "right"],
      clickToClose: false,
      lastOnBottom: true
    }),
    // provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideAnalytics(() => getAnalytics()),
    // provideFunctions(() => getFunctions()),
    // provideMessaging(() => getMessaging()),
    // providePerformance(() => getPerformance())
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,
    ToastModule,
    Sentry.TraceModule,

    // Modules
    RoleSelectorModule,
    DashboardModule,
    BackofficeDashboardModule,
    ComponentesModule,
    RequisicaoDocumentosModule,
  ],
  exports: [
    RouterModule,
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    AuthService,
    TruncatePipe,
    FileSizePipe,
    DynamicDialogConfig,
    ConfirmationService,
    DialogService,
    NgxExtendedPdfViewerService,
    PerformanceMonitoringService,
    MessageService,
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { 
        displayDefaultIndicatorType: false,
        showError: true
      },
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlCleanupInterceptor,
      multi: true
    },
    { 
      provide: CONFIG, 
      useValue: { send_page_view: true } 
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ]
})
export class AppModule {
}
