<div class="card">
  <p-table #dtDocumentos breakpoint="960px" [tableStyle]="{'min-width': '50rem'}" [value]="documentos" (onLazyLoad)="loadDocuments($event)" [lazy]="true" [loading]="loading"
    [(rows)]="limit" [totalRecords]="totalRecords" [paginator]="true" [scrollable]="true"  
    [globalFilterFields]="['nome','numero','fornecedor.nome','status']"
    [tableStyle]="{'min-width': '75rem'}" [rowsPerPageOptions]="[10,25,50,100]" 
    [rowHover]="true" dataKey="id" currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords}"
    [showCurrentPageReport]="true"  stateStorage="session" stateKey="listar-meus-documentos"
    >
    <ng-template pTemplate="caption">
      <div class="flex justify-content-space-between" style="justify-content: space-between!important;">
        <div class="flex justify-content-space-between">
          <!-- <button pButton pRipple [label]="Delete" icon="pi pi-trash" class="mr-2 p-button-raised p-button-danger"
            (click)="deleteSelected()" [disabled]="!selectedDocumentos || !selectedDocumentos.length"></button> -->
        <button pButton pRipple label="Novo" icon="pi pi-plus" class="mr-2 p-button-raised"
            (click)="criarNovo()" *ngIf="mapaDeAcoes.criar.includes(contexto)" ></button>
          <button pButton pRipple label="Limpar" class="p-button-outlined" icon="pi pi-filter-slash"
            (click)="clear(dtDocumentos,'comprador-listar-documentos')"></button>
        </div>
        <div class="flex justify-content-space-between">
          <!-- <span class="p-input-icon-left mr-2 line-height-normal">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dtDocumentos.filterGlobal($event.target.value, 'contains')"
              placeholder="Buscar..." />
          </span> -->
          <!-- <p-button class="p-button-raised" icon="pi pi-eye" (click)="messageShow()"></p-button> -->
          <p-button class="p-button-raised" icon="pi pi-refresh" (click)="dtDocumentos.reset();"></p-button>
        </div>
      </div>

    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <!-- <th style="width: 4rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th> -->
        <th pSortableColumn="nome" style="min-width:15rem">Nome 
          <p-sortIcon field="nome"></p-sortIcon>
          <p-columnFilter type="text" field="nome" display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="numero" style="min-width:10rem">Número 
          <p-sortIcon field="numero"></p-sortIcon>
          <p-columnFilter type="text" field="numero" display="menu"></p-columnFilter>
        </th>
        <!-- <th>Arquivo</th> -->
        <th pSortableColumn="tipo.nome" style="min-width:8rem">Tipo 
          <p-sortIcon field="tipo"></p-sortIcon>
          <p-columnFilter type="text" field="tipo.nome" display="menu"></p-columnFilter>
        </th>
        <!-- <th pSortableColumn="fornecedor">Fornecedor 
          <p-sortIcon field="fornecedor"></p-sortIcon>
        </th> -->
        <th pSortableColumn="fornecedor.nome" style="min-width:11rem"> {{  contexto == 'comprador' ? 'Fornecedor' : 'Empresa' }}
          <p-sortIcon field="fornecedor.nome"></p-sortIcon>
          <p-columnFilter class="header-center" type="text" field="fornecedor.nome"
          display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="fornecedor.cnpj" style="min-width:14rem"> {{  contexto == 'comprador' ? 'Fornecedor CNPJ' : 'Empresa CNPJ' }}
          <p-sortIcon field="fornecedor.cnpj"></p-sortIcon>
          <p-columnFilter class="header-center" type="text" field="fornecedor.rawCNPJ"
          display="menu"></p-columnFilter>
        </th>

        <!-- <th pSortableColumn="enviadoPor.email" style="min-width:10rem">Enviado Por <p-sortIcon field="enviadoPor">
          </p-sortIcon>
        </th> -->
        <th pSortableColumn="dataEmissao" style="min-width: 14rem">Emitido em
          <p-sortIcon field="dataEmissao"></p-sortIcon>
          <p-columnFilter type="date" field="dataEmissao" display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="dataValidade" style="min-width: 14rem">Válido até
          <p-sortIcon field="dataValidade"></p-sortIcon>
          <p-columnFilter type="date" field="dataValidade" display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="updatedAt" style="min-width: 12rem">Atualizaçāo
          <p-sortIcon field="updatedAt"></p-sortIcon>
          <p-columnFilter type="date" field="updatedAt" display="menu"></p-columnFilter>
        </th>
        <th pFrozenColumn ></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-documento>
      <tr>
        <td>{{ documento.nome || "-"}}</td>
        <td>{{ documento.numero || "-"}}</td>
        <td>{{ documento.tipo.nome || "-"}}</td>
        <td>{{ documento.fornecedor?.nome || "-"}}</td>
        <td>{{ documento.fornecedor?.cnpj || "-"}}</td>
        <td>{{ documento.dataEmissao | date: "dd/MM/yyyy" : 'UTC' }}</td>
        <td>{{ documento.dataValidade | date: "dd/MM/yyyy" : 'UTC' }}</td>
        <td>{{ documento.updatedAt | date: "dd/MM/yyyy HH:mm" : 'UTC' }}</td>
        <td alignFrozen="right" pFrozenColumn class="flex items-center">
          <button pButton pRipple icon="pi pi-eye" class="p-button-raised p-button-rounded p-button-secondary mr-2"
            (click)="show(documento)" *ngIf="mapaDeAcoes.visualizar.includes(contexto)" ></button>
          <button pButton pRipple *ngIf="mapaDeAcoes.renovar.includes(contexto)" icon="pi pi-send" class="p-button-raised p-button-rounded p-button-warning mr-2" (click)="renovar(documento)" pTooltip="Renove o documento" ></button>
          <button pButton pRipple [disabled]="documento.edicaoBloqueada" tooltipPosition="left" [tooltipDisabled]="!documento.edicaoBloqueada" pTooltip="Documento foi utilizado em uma requisiçāo e nāo pode ser alterado!" icon="pi pi-pencil" class="p-button-raised p-button-rounded p-button-primary mr-2" 
            (click)="edit(documento)" *ngIf="mapaDeAcoes.editar.includes(contexto)" ></button>
          <button pButton pRipple [disabled]="documento.edicaoBloqueada" tooltipPosition="left" [tooltipDisabled]="!documento.edicaoBloqueada" pTooltip="Documento foi utilizado em uma requisiçāo e nāo pode ser removido!" icon="pi pi-trash" class="p-button-raised p-button-rounded p-button-danger"
            (click)="delete(documento)" *ngIf="mapaDeAcoes.excluir.includes(contexto)" ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="8">
          Nenhum documento encontrado.
        </td>
      </tr>
    </ng-template>
  </p-table>  
</div>

<p-confirmDialog [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}"></p-confirmDialog>