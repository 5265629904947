import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { RequisicaoDocumentoService } from '@shared/services/requisicaoDocumento.service';
import { MessageService } from 'primeng/api';
import { catchError, EMPTY, Observable, tap, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequisicaoByDocumentoResolver implements Resolve<boolean> {
  constructor(
    private _requisicaoDocumentoService: RequisicaoDocumentoService,
    private _messageService: MessageService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const documentoId = route.params.id || route.params._id;

    if (documentoId === null || documentoId === undefined) {
      this._messageService.add({
        key: "app",
        severity: 'error',
        summary: 'Houve um erro',
        detail: 'é necessário informar o documento!'
      });
      return EMPTY;
    }

    return this._requisicaoDocumentoService.getDocumentByDocumentoId(documentoId).pipe(
        tap((response) => response),
        catchError((err) => {
          console.error('Erro detalhado:', err);
          this._messageService.add({
            key: "app",
            severity: 'error',
            summary: 'Houve um erro',
            detail: err?.error?.message || 'Erro ao buscar dados da Requisição de Documento'
          });
          return of(null); // Retorna null ao invés de EMPTY para evitar erro no resolver
        })
      );
    }
  }
