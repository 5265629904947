<div [ngClass]="inRoute ? 'content-layout fullwidth-standard-content-scroll' : ''">
  <!-- Header -->
  <div class="header" *ngIf="inRoute">
    <div class="left">
      <h1>{{ 'Editar Documento: ' + documento.nome+ ' #' + documento.id }}</h1>
    </div>
    <div class="right">
      <!-- Action buttons -->
    </div>
  </div>
  <div class="max-w">
    <form [formGroup]="form" autocomplete="disabled"
      class="flex flex-col p-8 pb-0 bg-card shadow-xl rounded overflow-hidden">
      <div class="flex flex-col gap-4">
       
        <div class="treo-mat-dense w-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 gap-4">
        <div class="treo-mat-dense flex-auto">
          <span class="label">Fornecedor</span>
          <input matInput class="mat-input-element" [ngModel]="documento?.fornecedor?.nome" readonly [ngModelOptions]="{standalone: true}"/>
        </div>
  
        <div class="treo-mat-dense flex-auto" >
          <span class="label">Tipo do Documento</span>
          <p-dropdown [options]="documentoTipos" class="mt-1 block w-full" formControlName="tipo" optionLabel="nome" optionValue="id" [editable]="false"
            [filter]="true" filterBy="nome" [showClear]="true" [virtualScroll]="true" [lazy]="true" [virtualScrollItemSize]="60" appendTo="body"
            placeholder="Selecione um tipo"></p-dropdown>
        </div>
      </div>

      <div class="treo-mat-dense w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4">
        <mat-form-field >
          <mat-label>Nome do Documento</mat-label>
          <input matInput formControlName="nome" required >
          <mat-error *ngIf="form && form.get('nome').hasError('required')">O campo Nome é obrigatório.</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Número</mat-label>
          <input matInput formControlName="numero" required>
          <mat-error *ngIf="form && form.get('numero').hasError('required')">O campo Número é obrigatório.</mat-error>
        </mat-form-field>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4">
        <div class="field">
          <label>Data de Emissão</label>
          <p-calendar 
            formControlName="dataEmissao"
            [showIcon]="true"
            dateFormat="dd/mm/yy"
            [maxDate]="hoje"
            [readonlyInput]="true"
            [style]="{'width':'100%'}"
            placeholder="Selecione a data"
            appendTo="body"
            class="w-full">
          </p-calendar>
          <small class="p-error block" *ngIf="form.get('dataEmissao')?.hasError('required')">
            O campo Data de Emissão é obrigatório.
          </small>
        </div>
      
        <div class="field">
          <label>Data de Validade</label>
          <p-calendar 
            formControlName="dataValidade"
            [showIcon]="true"
            dateFormat="dd/mm/yy"
            [minDate]="hoje"
            [maxDate]="dataValidadeMaxima"
            [readonlyInput]="true"
            [style]="{'width':'100%'}"
            placeholder="Selecione a data"
            appendTo="body"
            class="w-full">
          </p-calendar>
          <small class="p-error block" *ngIf="form.get('dataValidade')?.hasError('required')">
            O campo Data de Validade é obrigatório.
          </small>
          <small class="block text-primary-800 hover:text-primary-600" 
            *ngIf="modelosDocumento?.length && modelosDocumento[0]?.validadeDocumentoQuantidade && modelosDocumento[0]?.validadeDocumentoPeriodo">
            O modelo do cliente define validade máxima de {{ modelosDocumento[0].validadeDocumentoQuantidade }} 
            {{ modelosDocumento[0].validadeDocumentoPeriodo === 'dia' ? 'dias' : 
               modelosDocumento[0].validadeDocumentoPeriodo === 'semana' ? 'semanas' : 
               modelosDocumento[0].validadeDocumentoPeriodo === 'mes' ? 'meses' : 'anos' }}.
          </small>
        </div>
      </div>
      <div class="field col-12">
        <label class="block mb-2" for="observacao">Observação</label>
        <textarea 
          class="w-full border-2 rounded" 
          inputId="observacao" 
          rows="2" 
          cols="30" 
          pInputTextarea 
          maxlength="300"
          formControlName="observacao" 
          [ngClass]="{'ng-invalid ng-dirty': form.get('observacao')?.invalid && form.get('observacao')?.touched}"
          placeholder="Digite uma observação">
        </textarea>
        <small 
          class="p-error" 
          *ngIf="form.get('observacao')?.invalid && form.get('observacao')?.touched">
          O campo Observação é obrigatório.
        </small>
      </div>
      <div class="treo-mat-dense flex-auto">
        <label>Arquivo</label>
        <treo-message class="w-full" [type]="'basic'" [showIcon]="true"> <mat-icon class="mr-2" treoMessageIcon
            [svgIcon]="'insert_drive_file'"></mat-icon>
          <div class="flex items-center justify-between">
            <div class="flex-1">{{ getFileName(documento.file) }}</div>
            <div class="flex"> 
              <button mat-icon-button class="mx-2" (click)="downloadFile()" > <mat-icon>download</mat-icon> </button> 
              <button mat-icon-button class="mx-2" (click)="viewFile()" > <mat-icon>open_in_new</mat-icon> </button> </div>
          </div>
        </treo-message>
      </div>
      
      <div class="treo-mat-dense flex-auto mb-5">
        <label class="mb-3">Visualizar Arquivo</label>
        <div>
          <ng-container *ngIf="mimeType && mimeType.startsWith('image/')">
            <p-image [src]="urlFile" alt="Image" width="250" [preview]="true" (onImageError)="erroImage($event)" ></p-image>
          </ng-container>
          <ng-container *ngIf="mimeType && mimeType === 'application/pdf'">
            <ngx-extended-pdf-viewer [src]="urlFile" [height]="'600px'" [width]="'100%'" [useBrowserLocale]="true"
              [showToolbar]="false" [originalSize]="false" [minZoomLevel]="0.5" [maxZoomLevel]="3"
              [zoom]="'page-width'"></ngx-extended-pdf-viewer>
          </ng-container>
          <ng-container *ngIf="mimeType && !mimeType.startsWith('image/') && mimeType !== 'application/pdf'">
            <div class="flex flex-col items-center justify-center space-y-2">
              <p>O Documento com extensāo "<strong>{{ (mimeType.split("/")[1]).split(".")[1] }}</strong>" não pode ser visualizado aqui, será necessário realizar o download do arquivo.</p>
              <button pButton pRipple type="button" class="p-button-raised p-button-secondary" (click)="downloadFile()">Fazer o Download do Arquivo</button>
            </div>
          </ng-container>
          <ng-container *ngIf="documento == null">
            <h6 class="text-center">Documento indisponível para visualização.</h6>
          </ng-container>
        </div>
      </div>
      </div>
      <div
        class="flex items-center justify-end border-t -mx-8 mt-5 px-8 py-5 light:bg-cool-gray-50 dark:bg-cool-gray-700">
        <treo-message [dismissed]="!message.show" [appearance]="'border'" [showIcon]="'true'"
          [type]="message.type">
          {{message.content}}
        </treo-message>
        <button pButton pRipple label="Cancelar" *ngIf="inRoute" icon="pi pi-times" class="p-button-text"
          (click)="cancelar()"></button>
        <button mat-raised-button (click)="upload()" [class.spinner]="loading" [disabled]="loading" color="primary"
          class="px-6 ml-3">
          Salvar
        </button>
      </div>
    </form>
  </div>



