import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { DocumentoService } from '@shared/services/documento.service';
import { MessageService } from 'primeng/api';
import { EMPTY, Observable, catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentoResolver implements Resolve<boolean> {
  
  constructor(
    private _documentoService: DocumentoService,
    private _router: Router,
    private _messageService: MessageService,
  ) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const id = route.params.id || route.params._id;

    
    if (id === null || id === undefined) {
      this._messageService.add({
        key: "app",
        severity: "error",
        summary: "Houve um erro",
        detail: `é necessario informar um id!`,
        sticky: true,
      })
      return of(null);
    }
    return this._documentoService.getOne(id).pipe(catchError((err) => {
      console.error(err.error);
      this._messageService.add({
        key: "app",
        severity: "error",
        summary: "Houve um erro",
        detail: `Erro ao buscar documento: ${err.error.message}`,
        sticky: true,
      })
      return of(null);
    }));
  }
}
