import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/material/material.module';
import { SharedModule } from '@shared/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TreoMessageModule } from '@treo/components/message';
import { ComponentesModule } from 'app/core/componentes/componentes.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxMaskModule } from 'ngx-mask';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { DocumentoModule } from '../documentos/documento.module';
import { CriarEditarRequisitacaoDocumentoComponent } from './criar-editar-requisicao-documento/criar-editar-requisicao-documento.component';
import { ExibirAprovarRequisicaoComponent } from './exibir-aprovar-requisicao/exibir-aprovar-requisicao.component';
import { ListarRequisicaoComponent } from './listar-requisicao/listar-requisicao.component';
import { ResponderRequisicaoComponent } from './responder-requisicao/responder-requisicao.component';
import { ModeloDocumentoAlertModule } from 'app/core/componentes/modelo-documento-alert/modelo-documento-alert.module';

@NgModule({
  declarations: [
    CriarEditarRequisitacaoDocumentoComponent,
    ExibirAprovarRequisicaoComponent,
    ListarRequisicaoComponent,
    ResponderRequisicaoComponent,
  ],
  exports: [
    CriarEditarRequisitacaoDocumentoComponent,
    ExibirAprovarRequisicaoComponent,
    ListarRequisicaoComponent,
    ResponderRequisicaoComponent,
  ],
  imports: [
    CommonModule,
    // RequisicaoDocumentoRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TreoMessageModule,
    NgxDatatableModule,
    TableModule,
    InputTextModule,
    ButtonModule,
    NgxMaskModule.forRoot(),
    CalendarModule,
    TabViewModule,
    FileUploadModule,
    ConfirmDialogModule,
    DialogModule,
    ToastModule,
    ToolbarModule,
    DropdownModule,
    SkeletonModule,
    MaterialFileInputModule,
    AutoCompleteModule,
    DynamicDialogModule,
    ProgressSpinnerModule,
    SharedModule,
    InputMaskModule,
    DocumentoModule,
    NgxExtendedPdfViewerModule,
    MultiSelectModule,
    InputSwitchModule,
    SelectButtonModule,
    ConfirmPopupModule,
    ComponentesModule,
    EditorModule,
    TooltipModule,
    ImageModule,
    ModeloDocumentoAlertModule,
  ],
  providers: [
    DynamicDialogRef,
  ]
})
export class RequisicaoDocumentosModule { }
