import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import Utils from '@shared/helpers/utils';
import { Cliente } from '@shared/models/cliente.interface';
import { Documento } from '@shared/models/documento.interface';
import Fornecedor from '@shared/models/fornecedor.interface';
import { APIresponsePaginate } from '@shared/models/paginateResponse.interface';
import { DocumentoService } from '@shared/services/documento.service';
import { FileService } from '@shared/services/file.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-listar-documentos',
  templateUrl: './listar-documentos.component.html',
  styleUrls: ['./listar-documentos.component.scss']
})
export class ListarDocumentosComponent implements OnInit, OnDestroy {
  @Input('fornecedor') fornecedor: Fornecedor;
  @Input('cliente') cliente: Cliente = null;
  editDocumentoDialog: boolean = false;
  documentos: Documento[];
  documento: Documento;
  selectedDocumentos: Documento[];
  loading: boolean = false;
  private destroyed$ = new Subject();
  totalRecords: number = 0;
  offset: number = 0;
  limit: number = 10;
  totalPages: number;
  page: number = 1;
  pagingCounter: number = 1;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number;
  nextPage: number;
  somenteVisualizar: boolean = false;
  @ViewChild('dt') dt: Table;

  constructor(
    private _documentoService: DocumentoService,
    private _confirmationService: ConfirmationService,
    private _sanitizer: DomSanitizer,
    private _messageService: MessageService,
    private _fileService: FileService,
    private _router: Router,
    ) { 
      this._confirmationService
    }

  ngOnDestroy(): void {
    this._confirmationService.close();
  }

  ngOnInit() {
    this.load({});
  }

  load(event) {
    try {
      this.loading = true;
      this._documentoService.listarDocumentosDoFornecedor(this.fornecedor.id,event)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res: APIresponsePaginate) => {
          this.documentos = res.data.docs;
          this.totalRecords = res.data.totalDocs;
          this.limit = res.data.limit;
          this.loading = false
        }, (error) => {
          this.loading = false;
          console.error(error)
          this._messageService.add({ severity: 'error', summary: 'Houve um erro!', detail: error.error.message , sticky: true, key: "app" });
        });
    } catch (error) {
      this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `${error?.message}`, sticky: true, key: "app" })
    }
  }

  showImage(image) {
    return this._sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + image)
  }

  viewFile(documento) {
    const url = this._fileService.urlViewFile(documento.id);
    window.open(url, '_blank');
  }

  downloadFile() {
    const url = this._fileService.urlDownloadFile(this.documento.file.id || this.documento.file._id);
    window.open(url, '_blank');
  }

  edit(documento: Documento) {
    this.somenteVisualizar = false;
    this.documento = documento;
  }

  renovar(documento: Documento) {
    this._router.navigate([`/documentos/renovar/${documento.id}`]);
  }

  show(documento: Documento) {
    this.somenteVisualizar = true;
    this.documento = documento;
  }

  delete(documento: Documento) {
    try {
      const confirmRef = this._confirmationService.confirm({
        message: 'Você realmente deseja deletar o documento ' + documento.nome + '?',
        header: 'Confirmar',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this._documentoService.deleteOne(documento.id).subscribe(
            (res: any) => {
              
              this.documentos = this.documentos.filter(val => val.id !== documento.id);
              this.documento = {};
              this._messageService.add({ severity: 'success', summary: 'Ótimo', detail: 'documento deletado' , key: "app" });
            },
            (error: any) => {
              console.error(error)
              this._messageService.add({ severity: 'error', summary: 'Houve um erro!', detail: error.error.message, sticky: true , key: "app" });
            }
          )
        },
        reject: ()=>{
          confirmRef.close()
        }
      });
    } catch (error) {
      this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `${error?.message}`, sticky: true, key: "app" })
    }
  }

  resetDialog(event) {
    this.editDocumentoDialog = event;
  }

  createdAndEditedDocumento(documento) {
    this.editDocumentoDialog = false;
    this.documento = null;
    const index = this.findIndexById(documento.id);
    if (index !== -1) {
      this.documentos[index] = documento;
    } else {
      this.documentos.push(documento);
    }
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.documentos.length; i++) {
      if (this.documentos[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  clear(table: Table, storageKey: string) {
    Utils.clearTableFilters(table, storageKey)
  }
}