import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class DocumentoService {


  readonly SERVER_URL = environment.apiEndpoint;
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthService,
    private _cookieService: CookieService,
  ) { }

  listarDocumentos(event): Observable<any> {
    let options;
    if(event){
      delete event.forceUpdate;
      event.filters = JSON.stringify(event.filters);
      options = { params: event };
    }
    return this._httpClient.get<any>(`${this.SERVER_URL}documento/list`, options);
  }
    listarDocumentosDoFornecedor(id, event): Observable<any> {
    let options = {};
    if (event) {
      delete event.forceUpdate;
      event.filters = JSON.stringify(event.filters);
      options = { params: event };
    }
    /**
      Adiciona o token de autenticação no header da requisicao porque 
      tem um BUG que não está enviando o token
      pelo interceptor padrao do angular, a solução foi adicionar manualmente
    */
    const authToken = this._cookieService.get(environment.cookie.authName);
    const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);
    options = { ...options, headers: headers };
  
    return this._httpClient.get<any>(`${this.SERVER_URL}documento/fornecedor/${id}`, options);
  }
  listarDocumentosDoFornecedorProspect(id, event): Observable<any> {
    
    let options;
    if(event){
      delete event.forceUpdate;
      event.filters = JSON.stringify(event.filters);
      options = { params: event };
    }
    return this._httpClient.get<any>(`${this.SERVER_URL}prospect/documento/fornecedor/${id}`, options);
  }
  listarDocumentosDoVendedor(id, event): Observable<any> {
    let options;
    if(event){
      delete event.forceUpdate;
      event.filters = JSON.stringify(event.filters);
      options = { params: event };
    }
    return this._httpClient.get<any>(`${this.SERVER_URL}documento/vendedor/${id}`, options);
  }
  criarRequisicaoAutomaticaProspect(
    tipo: string,
    fornecedor: string,
    cliente: string,
    documento: string
  ){

    return this._httpClient.post<any>(`${this.SERVER_URL}/prospect/documento/requisicao`, { tipo, fornecedor, cliente, documento });
  }
  // listarDocumentosDoCliente(id, event): Observable<any> {
  //   let options;
  //   if(event){
  //     delete event.forceUpdate;
  //     event.filters = JSON.stringify(event.filters);
  //     options = { params: event };
  //   }
  //   return this._httpClient.get<any>(`${this.SERVER_URL}documento/cliente/${id}`, options);
  // }
  // Obtém a lista de documentos de requisição
  listarDocumentosDoCliente(id, event): Observable<any> {
    let options;
    if(event){
      delete event.forceUpdate;
      event.filters = JSON.stringify(event.filters);
      options = { params: event };
    }
    const url = `${environment.apiEndpoint}documento/cliente/${id}/list`;

    return this._httpClient.get<any>(url, options);
  }

  listarDocumentosEntreClienteEhFornecedor(clienteId,fornecedorId, event): Observable<any> {
    let options;
    if(event){
      delete event.forceUpdate;
      event.filters = JSON.stringify(event.filters);
      options = { params: event };
    }
    const url = `${environment.apiEndpoint}/documento/cliente/${clienteId}/fornecedor/${fornecedorId}/list`;

    return this._httpClient.get<any>(url, options);
  }

  getOne(id): Observable<any> {
    return this._httpClient.get<any>(`${this.SERVER_URL}documento/${id}`);
  }
  uploadNew(fornecedor,documento): Observable<any> {
    return this._httpClient.post<any>(`${this.SERVER_URL}documento/fornecedor/${fornecedor}`, documento);
  }
  updateOne(id, documento): Observable<any> {
    return this._httpClient.patch<any>(`${this.SERVER_URL}documento/${id}`, JSON.parse(JSON.stringify(documento)));
  }
  deleteOne(id): Observable<any> {
    return this._httpClient.delete<any>(`${this.SERVER_URL}documento/${id}`);
  }
  renovarDocumento(id,documento:FormData): Observable<any> {
    return this._httpClient.post<any>(`${this.SERVER_URL}documento/renovar/${id}`, documento);
  }
}
